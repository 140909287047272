import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, useMediaQuery } from "@mui/material";
import { Inspector } from "react-inspector";

function LocalStateDialog({ open, onClose, localState }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="local-state-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
    >
      <DialogTitle id="local-state-dialog-title">Local state</DialogTitle>
      <DialogContent dividers>
        <Inspector
          theme={theme.palette.mode === "dark" ? "chromeDark" : "chromeLight"}
          data={localState}
          expandLevel={2}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LocalStateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  localState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  localState: state,
});

export default connect(mapStateToProps)(LocalStateDialog);
