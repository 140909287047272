import { call, put, cancelled, select, takeEvery } from "redux-saga/effects";
import api from "lib/axios";
import moment from "moment";
import readProp from "lib/readProp";

// Constants
const USERS_TABLE_NAME = "is_uzivatel";
const RIGHTS_TABLE_NAME = "is_prava";
const OPTIONS_TABLE_NAME = "userOptions";

/**
 * Login to API, load user data, update last_login timestamp and load user level.
 *
 * @param {string} email
 * @param {string} password
 */
function* authorize({ email, password }) {
  //let updateTask;
  try {
    //console.log('login requested...')
    // Get CSRF token
    //const csrf = yield call(API.obtainNewToken, email, password)
    const csrfRequest = yield call(api.post, "", { username: email, password });
    const csrf = csrfRequest.data;
    // Update token in local storage
    if (csrf) {
      yield sessionStorage.setItem("csrf", csrf);
      yield sessionStorage.setItem("invalidateToken", false);
      yield sessionStorage.setItem("autoLogin", true);
      yield put({ type: "DB_TOKEN_UPDATED" });
    }
    // Get user data
    const rawUser = yield call(api.get, USERS_TABLE_NAME, { params: { filter: `email,eq,${email}` } });
    //const parsedUser = rawUser[USERS_TABLE_NAME][0]
    const parsedUser = rawUser.data?.records?.[0];
    // Get user options
    const userOptions = yield call(api.get, OPTIONS_TABLE_NAME, { params: { filter: `user,eq,${parsedUser.id}` } });
    const options = {};
    Object.entries(userOptions.data?.records?.[0] || {})?.forEach(([key, value]) => {
      if (key !== "id" && key !== "user") options[`__${key}`] = value;
    });
    // Set dark theme if forced
    if (options?.forceDarkMode) yield put({ type: "APP_THEME_UPDATE_REQUESTED", theme: "dark" });
    // Get user rights
    const userRights = yield call(api.get, `${RIGHTS_TABLE_NAME}/${parsedUser.opravneni}`);
    // Get user level
    const userLevel = userRights?.data?.uroven || 0;
    // Last login
    const last_login = moment().format("YYYY-MM-DD HH:mm:ss");
    yield sessionStorage.setItem("last_login", last_login);
    // Construct user
    const user = { ...parsedUser, csrf, last_login, userLevel, ...options };
    // Update last login
    //yield call(API.put, `${USERS_TABLE_NAME}/${user.id}`, csrf, { last_login })
    yield call(api.put, `${USERS_TABLE_NAME}/${user.id}`, { last_login });
    // Change state
    yield put({ type: "USER_LOGIN_SUCCEEDED", user });
    // Return user
    return user;
  } catch (error) {
    yield put({ type: "USER_LOGIN_FAILED", error });
  } finally {
    if (yield cancelled()) {
      // ... put special cancellation handling code here
    }
  }
}

/**
 * Functions updates last_login in session state and also in database.
 */
function* updateLastLogin() {
  const state = yield select();
  const { user } = state;
  const last_login = moment().format("YYYY-MM-DD HH:mm:ss");
  yield sessionStorage.setItem("last_login", last_login);
  //console.log('Update last_login to ' + last_login)
  yield call(api.put, `is_uzivatel/${user.id}`, { last_login });
}

/**
 * Update user's data in local storage.
 * @param {{ data: Object }} param0 User data
 */
function* updateUser({ data }) {
  const state = yield select();
  let user = { ...state.user };
  const table = readProp(() => data[USERS_TABLE_NAME]) || [];
  const newUserData = table.find((u) => u.id === user.id);

  // Check updates
  if (user.edited !== readProp(() => newUserData.edited) || user.deleted !== readProp(() => newUserData.deleted)) {
    // Dispatch changes
    yield put({ type: "USER_UPDATED", user: newUserData });
  }
}

/**
 * Update user's level in local storage.
 * @param {{ payload: Object }} param0
 */
function* userLevelChange({ payload }) {
  yield put({ type: "USER_LEVEL_CHANGE_REQUESTED", payload });
}

function* beOffline() {
  // Prevent automatic login
  yield localStorage.removeItem("autoLogin");
  yield sessionStorage.removeItem("autoLogin");
}

/**
 * Root user saga function.
 */
export function* rootUser() {
  yield takeEvery("USER_LOGOUT", beOffline);
  yield takeEvery("USER_LOGIN_FAILED", beOffline);
  yield takeEvery("USER_LOGIN_REQUESTED", authorize);
  yield takeEvery("USER_UPDATE_LAST_LOGIN", updateLastLogin);
  yield takeEvery("DB_UPDATED_DATA", updateUser);
  yield takeEvery("USER_LEVEL_CHANGE", userLevelChange);
}
