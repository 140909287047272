import { php_crud_api_transform } from "./php-crud-api-transform";
import md5 from "lib/md5";
import isSet from "lib/isSet";
import sleep from "lib/sleep";

// php-crud-api: https://github.com/mevdschee/php-crud-api
// php-api-auth: https://github.com/mevdschee/php-api-auth
// JavaScript Classes: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Classes
// JavaScript Fetch API: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch

// API address
//const API_URL = "https://api.global-dtp.com/api.php/";
//const CUSTOM_API_URL = "https://api.global-dtp.com/custom.php";
const API_URL = process.env.REACT_APP_API_URL;
const CUSTOM_API_URL = process.env.REACT_APP_CUSTOM_API_URL;

/**
 * Transform denormalized JSON data to normalized JSON data.
 * @param {object} json JSON object ro normalize
 * @returns {object}
 */
const normalizeJSON = (json) => php_crud_api_transform(json);

function getInvalidationStatus() {
  try {
    return sessionStorage.getItem("invalidateToken");
  } catch (e) {
    return undefined;
  }
}

/**
 * Return URI part with parameters.
 * @param {object} [params=null] Set of parameters to parse
 * @returns {object}
 */
const prepareParams = (params = null) => {
  let newParams = [];

  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        if (/^filter\d*$/.test(key)) {
          //if (key === 'filter') {
          value.forEach((filter) => {
            if (Array.isArray(filter)) {
              newParams.push([`${key}[]`, filter.join(",")].join("="));
            } else {
              newParams.push([`${key}[]`, filter].join("="));
            }
          });
        } else if (key === "order") {
          let oneOrder = [];
          let multipleOrder = [];
          value.forEach((order) => {
            if (Array.isArray(order)) {
              multipleOrder.push(order.join(","));
            } else {
              oneOrder.push(order);
            }
          });
          if (multipleOrder.length > 0) {
            multipleOrder.forEach((order) => newParams.push([`${key}[]`, order].join("=")));
          } else {
            newParams.push([`${key}`, oneOrder].join("="));
          }
        } else if (key === "page") {
          if (parseInt(value[1], 10) > 0) newParams.push([key, value.join(",")].join("="));
        } else {
          newParams.push([key, value.join(",")].join("="));
        }
      } else if (value && String(value).length > 0) {
        newParams.push([key, value].join("="));
      }
    });
  }

  return newParams.join("&");
};

/**
 * This wrapper makes the request cancellable by React Query.
 * @param {Function} asyncFn Asynchronnous function that will include signal as a parameter
 */
export const cancelableRequest =
  (asyncFn) =>
  (...args) => {
    // Create new instance of the AbortContoller interface
    const controller = new AbortController();
    // Bind its signal property to variable
    const signal = controller.signal;
    // Include signal as parameter in our async function
    const promise = asyncFn(...args, signal);
    // Cancel the request if React Query calls the `promise.cancel` method
    promise.cancel = controller.abort;
    // Return
    return promise;
  };

/**
 * API POST request.
 * @param {string} [table=null] Table name
 * @param {object} [body={}] Parameters
 * @param {boolean} [normalize=false] Normalize output data
 * @param {AbortSignal} [signal]
 */
export const post = (table = null, body = {}, normalize = false, signal) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      credentials: "include",
      //mode: 'cors', // no-cors, cors, *same-origin
      ...(signal ? { signal } : {}),
    };

    // Add body if options exists
    if (body) options.body = JSON.stringify(body);

    // Construct url
    let url = API_URL;

    // Add table
    if (table) url += table;

    // Post data via Fetch API and parse output
    fetch(url, options)
      .then((response) => response.clone())
      .then((clonned) =>
        clonned
          .json()
          .then((json) => (normalize ? normalizeJSON(json) : json))
          .then((json) => resolve(json))
          .catch(() => resolve(clonned.text()))
      )
      .catch((error) => reject(error));
  });
};

/**
 * POST json data into database.
 * @param {string} path DB path
 * @param {string} csrf Unique CSRF Token
 * @param {object} jsonData Parameters (JSON data)
 * @param {AbortSignal} [signal]
 */
export const postj = (path = null, csrf = null, jsonData = {}, signal) => {
  return new Promise((resolve, reject) => {
    // Validation checks
    if (!csrf) reject("Token must be provided!");
    if (!path) reject("Path must be specified.");
    if (!jsonData || Object.keys(jsonData).length === 0) {
      reject("Parameters must be specified.");
    }

    // Construct URL and options
    let url = API_URL + path + "?csrf=" + csrf;
    let options = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(jsonData),
      //mode: 'cors', // no-cors, cors, *same-origin
      ...(signal ? { signal } : {}),
    };

    // Fetch request
    fetch(url, options)
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
};

/**
 * API GET request.
 * @param {string} table Table name
 * @param {object} params Parameters (should inclide CSRF Token)
 * @param {boolean} [normalize=false] Normalize output data
 * @param {AbortSignal} [signal]
 */
export async function get(table, params, normalize = false, signal) {
  try {
    let options = {
      credentials: "include",
      //mode: 'cors', // no-cors, cors, *same-origin
      //method: 'GET',
      //mode: 'cors',
      ...(signal ? { signal } : {}),
    };
    // Verify table
    if (!table) throw Error("Please specify table.");
    // Construct url
    let url = API_URL + table;
    // Verify parameters
    if (!params) throw Error("Please specify parameters.");
    // Handle params
    if (params) url += "?" + prepareParams(params);
    // Fetch data (get response)
    const response = await fetch(url, options);
    // Catch request errors
    if (!response.ok) {
      //console.group('Request "get" failed!')
      //console.log(response)
      //console.groupEnd()
      return { ok: response.ok, status: response.status };
    }
    // Get headers and recognize JSON type
    const contentType = await response.headers.get("content-type");
    const isJSON = contentType && contentType.indexOf("application/json") !== -1;
    // Costruct restult
    if (isJSON) {
      const raw = await response.json();
      const json = normalize ? normalizeJSON(raw) : raw;
      return { ok: response.ok, status: response.status, ...json };
    } else {
      const raw = await response.text();
      return { ok: response.ok, status: response.status, raw };
    }
  } catch (error) {
    console.log(error);
    return error;
  }
}

/**
 * PUT data into database.
 * @param {string} path DB path
 * @param {string} csrf Unique CSRF Token
 * @param {object} jsonData Parameters
 * @param {AbortSignal} [signal]
 */
export const put = (path = null, csrf = null, jsonData = {}, signal) => {
  return new Promise((resolve, reject) => {
    // Validation checks
    if (!csrf) reject("Token must be provided!");
    if (!path) reject("Path must be specified.");
    if (!jsonData || Object.keys(jsonData).length === 0) {
      reject("Parameters must be specified.");
    }

    let url = API_URL + path + "?csrf=" + csrf;
    let options = {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(jsonData),
      //mode: 'cors', // no-cors, cors, *same-origin
      ...(signal ? { signal } : {}),
    };

    // Fetch request
    fetch(url, options)
      .then((response) => resolve(response.json()))
      .catch((error) => reject(error));
  });
};

/**
 * Custom GET API request.
 * @param {object} request Special parameters
 * @param {AbortSignal} [signal]
 */
export async function getc(request = {}, signal) {
  try {
    let options = {
      credentials: "include",
      //mode: 'cors', // no-cors, cors, *same-origin
      ...(signal ? { signal } : {}),
    };
    let url = CUSTOM_API_URL + "?";
    let urlParams = [];

    Object.keys(request).forEach((key) => urlParams.push(`${key}=${request[key]}`));
    url += urlParams.join("&");

    // Fetch data
    const response = await fetch(url, options);
    // Catch request errors
    if (!response.ok) {
      //console.group('Request "getc" failed!')
      //console.log(req)
      //console.groupEnd()
      return { ok: response.ok, status: response.status };
    }
    // Get headers and recognize JSON type
    const contentType = await response.headers.get("content-type");
    const isJSON = contentType && contentType.indexOf("application/json") !== -1;
    // Costruct restult
    if (isJSON) {
      const json = await response.json();
      return { ok: response.ok, status: response.status, json };
    } else {
      const raw = await response.text();
      return { ok: response.ok, status: response.status, raw };
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
}

/**
 * Create data in database.
 * @param {string} table Table where create new records.
 * @param {object|array} values Record(s) to be created.
 * @param {AbortSignal} [signal]
 */
export function create(table, values, signal) {
  return new Promise((resolve, reject) => {
    const csrf = sessionStorage.getItem("csrf");
    const request = { csrf, table, values };
    let result = null;
    let error = false;

    const asyncHandle = async () => {
      try {
        // Create new records in database.
        result = await postj(table, csrf, values, signal);

        if (!result) {
          error = {
            name: "CREATE_FAILED",
            message: "Failed to create a new record(s).",
          };
        }

        resolve(result, error, request);
      } catch (error) {
        reject(result, { name: error.name, message: error.message }, request);
      }
    };

    asyncHandle();
  });
}

/**
 * Update records in database.
 * @param {string} table Table name.
 * @param {number|array} itemIDs Record ID(s) to be affected.
 * @param {Object|array} values Values to be changed.
 * @param {AbortSignal} [signal]
 */
export function update(table, itemIDs, values, signal) {
  return new Promise((resolve, reject) => {
    const csrf = sessionStorage.getItem("csrf");
    let result = null;
    let error = false;
    let request = null;

    const asyncHandle = async () => {
      try {
        // Parse ID(s)
        const ids = Array.isArray(itemIDs) ? itemIDs.join(",") : itemIDs;
        // Construct path
        const path = table + "/" + ids;
        request = { csrf, path, values };

        // Update data in database.
        // We get a number (0/1) for one value,
        // or array of results (0/1) for multiple values.
        result = await put(path, csrf, values, signal);

        // Array with IDs which were not completed properly.
        const errorIDs = [];
        // For multiple items
        if (Array.isArray(result)) {
          result.forEach((r, i) => {
            if (r === 0) errorIDs.push(ids[i]);
          });
        }
        // For just one item
        else if (result !== 1) {
          errorIDs.push(ids);
        }

        // Return error message if one or more items was not affected.
        if (errorIDs.length > 0) {
          error = {
            name: "UPDATE_FAILED",
            message: `Following IDs were not updated: ${errorIDs.join(", ")}`,
          };
        }

        resolve(result, error, request);
      } catch (error) {
        reject(result, { name: error.name, message: error.message }, request);
      }
    };

    asyncHandle();
  });
}

/**
 * Read records from database.
 * @param {string} table Table from which read records
 * @param {Object} query Query object
 * @param {boolean} includeRecords Include number of records
 * @param {AbortSignal} [signal]
 */
export function read(table, query, includeRecords = false, signal) {
  return new Promise((resolve, reject) => {
    let csrf = sessionStorage.getItem("csrf");
    const request = { csrf, table, query };
    let result = [];
    let error = false;
    let raw = null;
    let records = 0;

    const asyncHandle = async () => {
      let skip = false;
      try {
        // Retry if we are currently getting a new token
        const invalidationInProcess = getInvalidationStatus();
        if (invalidationInProcess === "REQUESTED" || invalidationInProcess === "PROCESSING") {
          // Do not return anything
          skip = true;
          // Wait 5 seconds
          await sleep(5000);
          // Repeat request
          asyncHandle();
        }

        if (!skip) {
          // Update token
          csrf = sessionStorage.getItem("csrf");
          request.csrf = csrf;
          if (!csrf) {
            // If this value is true, state checks will handle reconnection
            sessionStorage.setItem("invalidateToken", "REQUESTED");
            // Do not return anything
            skip = true;
            // Wait 10 seconds
            await sleep(10000);
            // Repeat request
            asyncHandle();
          }
          // Get data from database.
          raw = await get(table, { csrf, ...query }, true, signal);
          //console.log(raw)

          if (Array.isArray(raw[table])) {
            result = Array.isArray(raw[table]) ? raw[table] : [raw[table]];

            // Get records
            records = raw?._results || result.length;

            if (raw[table].length === 0) {
              error = {
                name: "NO_RESULTS",
                message: "No results found.",
              };
            }
          } else {
            // 401 - Access denied (bad token)
            if (raw.status === 401) {
              // If this value is true, state checks will handle reconnection
              sessionStorage.setItem("invalidateToken", "REQUESTED");
              // Do not return anything
              skip = true;
              // Wait 10 seconds
              await sleep(10000);
              // Repeat request
              asyncHandle();
            } else {
              //sessionStorage.setItem('invalidateToken', 'FAILED')
              error = {
                name: "LOADING_FAILED",
                message: "Fetching data from database failed.",
              };
            }
          }
        }

        if (!skip) {
          if (includeRecords) resolve({ result, records });
          else resolve(result, error, request, raw);
        }
      } catch (error) {
        console.error(error);
        reject(result, error, request, raw);
      }
    };

    asyncHandle();
  });
}

/**
 * Read records from database using customized API function.
 * @param {string} request Custom request identification.
 * @param {object} parameters Parameters for custom request.
 * @param {AbortSignal} [signal]
 */
export function readCustom(request, parameters = {}, signal) {
  return new Promise((resolve, reject) => {
    let csrf = sessionStorage.getItem("csrf");
    const getParameters = { csrf, request, ...parameters };
    let result = null;

    const asyncHandle = async () => {
      let skip = false;
      try {
        // Retry if we are currently getting a new token
        const invalidationInProcess = getInvalidationStatus();
        if (invalidationInProcess === "REQUESTED" || invalidationInProcess === "PROCESSING") {
          // Do not return anything
          skip = true;
          // Wait 5 seconds
          await sleep(5000);
          // Repeat request
          asyncHandle();
        }

        if (!skip) {
          csrf = sessionStorage.getItem("csrf");
          getParameters.csrf = csrf;
          // Get custimized data
          result = await getc(getParameters, signal);

          if (!result.ok) {
            // 401 - Access denied (bad token)
            if (result.status === 401) {
              // If this value is true, state checks will handle reconnection
              sessionStorage.setItem("invalidateToken", "REQUESTED");
              // Do not return anything
              skip = true;
              // Wait 10 seconds
              await sleep(10000);
              // Repeat request
              asyncHandle();
            } else {
              throw new Error("Fetching data from database failed. " + JSON.stringify({ request, parameters }));
            }
          }
        }

        if (!skip) {
          // Return result
          //console.log(result?.json || result?.raw || null)
          resolve(result?.json || result?.raw || null, null, getParameters);
        }
      } catch (error) {
        //console.log("Error in api/readCustom:", error.message);
        reject(result?.json || result?.raw || null, { name: "Error in api/readCustom", message: error }, getParameters);
      }
    };

    asyncHandle();
  });
}

/**
 * Move (or rename) directory on production server.
 * @param {string} oldPath From path.
 * @param {string} newPath To path.
 */
export function moveDir(oldPath, newPath) {
  return new Promise((resolve, reject) => {
    try {
      // Get CSRF
      const csrf = sessionStorage.getItem("csrf");
      // Construct URL
      let url = CUSTOM_API_URL + "?csrf=" + csrf;
      // Construct parameters
      const parameters = {
        request: "dir",
        operation: "move",
        value1: oldPath,
        value2: newPath,
      };
      // Parse GET parameters
      Object.entries(parameters).forEach(([key, value]) => {
        url += "&";
        url += encodeURI(key);
        url += "=";
        url += encodeURI(value);
      });
      // Execute request
      fetch(url, { credentials: "include" })
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Search path for specific directory name (partial or full match).
 * @param {string} path Path where looking for needle.
 * @param {string} needle Needle to be found.
 */
export function checkDir(path, needle) {
  return new Promise((resolve, reject) => {
    try {
      // Get CSRF
      const csrf = sessionStorage.getItem("csrf");
      // Construct URL
      let url = CUSTOM_API_URL + "?csrf=" + csrf;
      // Construct parameters
      const parameters = {
        request: "dir",
        operation: "check",
        value1: path,
        value2: needle,
      };
      // Parse GET parameters
      Object.entries(parameters).forEach(([key, value]) => {
        url += "&";
        url += encodeURI(key);
        url += "=";
        url += encodeURI(value);
      });
      // Execute request
      fetch(url, { credentials: "include" })
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Find the next free number (project/analysis number).
 * @param {string} path Path where looking for the next number.
 * @param {string} year Look only for particular year.
 */
export function nextFreeDirNumber(path, year) {
  return new Promise((resolve, reject) => {
    try {
      // Get CSRF
      const csrf = sessionStorage.getItem("csrf");
      // Construct URL
      let url = CUSTOM_API_URL + "?csrf=" + csrf;
      // Construct parameters
      const parameters = {
        request: "dir",
        operation: "next",
        value1: path,
        value2: year,
      };
      // Parse GET parameters
      Object.entries(parameters).forEach(([key, value]) => {
        url += "&";
        url += encodeURI(key);
        url += "=";
        url += encodeURI(value);
      });
      // Execute request
      fetch(url, { credentials: "include" })
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Create a new directory.
 * @param {string} path Path where the new directory have to be created.
 * @param {string} name Name of the new directory.
 */
export function createDir(path, name) {
  return new Promise((resolve, reject) => {
    try {
      // Get CSRF
      const csrf = sessionStorage.getItem("csrf");
      // Construct URL
      let url = CUSTOM_API_URL + "?csrf=" + csrf;
      // Construct parameters
      const parameters = {
        request: "dir",
        operation: "create",
        value1: path,
        value2: name,
      };
      // Parse GET parameters
      Object.entries(parameters).forEach(([key, value]) => {
        url += "&";
        url += encodeURI(key);
        url += "=";
        url += encodeURI(value);
      });
      // Execute request
      fetch(url, { credentials: "include" })
        .then((response) => response.json())
        .then((json) => resolve(json))
        .catch((error) => reject(error));
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Async function will get new token from server and save it to the session storage.
 *
 * @param {string=} email User's email
 * @param {string=} password User's password
 * @returns `boolean` (True if new token was generated, False if failure)
 */
export async function obtainNewToken(email, password) {
  try {
    const username = email || sessionStorage.getItem("email");
    const pwd = password || sessionStorage.getItem("password");

    // Throw error if values are not specified
    if (!isSet(username)) throw new Error("Unable to login without email!");
    if (!isSet(pwd)) throw new Error("Unable to login without password!");

    // Login/get new CSRF Token
    const csrf = await post(null, { username, password: md5(pwd) });
    if (isSet(csrf)) {
      // Update session storage
      sessionStorage.setItem("csrf", csrf);
      // Return new CSRF Token
      return csrf;
    }
    // If error occured during retriveing, throw error
    else {
      throw new Error("Failed to login!");
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    //throw new Error('Unable to obtain new token!')
    return false;
  }
}
