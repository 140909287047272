import { combineReducers } from 'redux'
import appReducer from './app.reducer'
import userReducer from './user.reducer'
import dbReducer from './db.reducer'
import snacksReducer from './snacks.reducer'

export default combineReducers({
  app: appReducer,
  user: userReducer,
  db: dbReducer,
  snacks: snacksReducer,
})
