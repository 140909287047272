import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Avatar, Tooltip } from "@mui/material";

function UserAvatar({ tooltip, placement, text, multiple, light, userID, isMe, onClick, isError }) {
  const navigate = useNavigate();

  /**
   * Handles mouse click on avatar.
   * @param {object} event Mouse event object.
   * @memberof UserAvatar
   */
  const handleClick = (event) => {
    event.preventDefault();
    if (event.ctrlKey && userID) {
      event.stopPropagation();
      navigate("/u/" + userID);
    } else if (typeof onClick === "function") onClick(event);
  };

  /**
   * Render user avatar.
   * @param {string} text Text inside avatar; usually 2-3 letters.
   * @param {boolean} multiple Lower left margin if true.
   * @param {boolean} light Render light version if true.
   * @memberof UserAvatar
   */
  const renderAvatar = (text, multiple, light) => (
    <Avatar
      sx={(theme) => ({
        width: 36,
        height: 36,
        m: 0,
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        border: "1px solid transparent",
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[500] : theme.palette.grey[700],
        color: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[200],
        ...(multiple && { marginRight: "-8px" }),
        ...(light && {
          border: "1px solid " + theme.palette.divider,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
        }),
        ...(isMe !== undefined &&
          isMe && {
            border: "1px solid transparent",
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[500] : theme.palette.grey[700],
            color: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.grey[200],
          }),
        ...(isMe !== undefined &&
          !isMe && {
            border: "1px solid " + theme.palette.divider,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
          }),
        ...(isError && {
          border: "1px solid " + theme.palette.divider,
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        }),
        ...(typeof onClick === "function" && { cursor: "pointer" }),
      })}
      onClick={handleClick}>
      {text}
    </Avatar>
  );

  // Without tooltip
  if (tooltip.length === 0) {
    return renderAvatar(text, multiple, light);
  }

  // With tooltip
  return (
    <Tooltip title={tooltip} placement={placement} disableInteractive>
      {renderAvatar(text, multiple, light)}
    </Tooltip>
  );
}

UserAvatar.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
  multiple: PropTypes.bool,
  light: PropTypes.bool,
  userID: PropTypes.number,
  isMe: PropTypes.bool,
  onClick: PropTypes.func,
  isError: PropTypes.bool,
};

UserAvatar.defaultProps = {
  tooltip: "",
  //placement: "bottom",
  multiple: false,
  light: false,
  userID: null,
  isError: false,
};

export default UserAvatar;
