import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery /*, queryCache*/ } from "@tanstack/react-query";
import api from "lib/axios";
import ListboxComponent from "./ListboxComponent";
import { useDebounce } from "@uidotdev/usehooks";
import { decodeHtml } from "lib/decode-html";

function ProjectNameSelect({ value, onChange, inputProps, ...otherProps }) {
  const [innerValue, setInnerValue] = useState("");
  const debouncedValue = useDebounce(innerValue, 500);

  // Get suggested names
  const { data: suggestions, isFetching } = useQuery({
    queryKey: ["project-name-suggestions", debouncedValue],
    queryFn: ({ queryKey, signal }) =>
      api.get("", { params: { request: "projectNameSuggestions", filter: queryKey[1] }, signal }),
    select: (res) => res.data,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // Update internal state when outer value change
    if (value !== innerValue) {
      setInnerValue(typeof value === "string" ? value : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const data = useMemo(() => {
    let newData = [];
    if (suggestions) {
      newData = suggestions
        .map((suggestion) => String(decodeHtml(suggestion)).trim())
        .filter((suggestion) => !!suggestion?.length)
        .sort();
    }
    return newData;
  }, [suggestions]);

  /**
   * Handle autocomplete change.
   * @param {Event} event Provided Event
   * @param {string} newValue New value
   */
  const handleChange = (event, newValue) => {
    event.preventDefault();
    if (newValue !== value) {
      // Update local state
      setInnerValue(newValue || "");
      // Notify parent about changes
      onChange(newValue || "");
    }
  };

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disableListWrap
      //classes={{ listbox: classes.listbox }}
      sx={{
        "& .MuiAutocomplete-listbox": {
          boxSizing: "border-box",
          "& ul": {
            padding: 0,
            margin: 0,
          },
        },
      }}
      ListboxComponent={ListboxComponent}
      inputValue={innerValue}
      onChange={handleChange}
      onInputChange={handleChange}
      options={data || []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          //label={inputProps.label}
          placeholder="Začni psát a/nebo vyber..."
          {...(inputProps ? inputProps : {})}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Typography {...props} noWrap>
          {option}
        </Typography>
      )}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => {
          const haystack = String(option).toLocaleLowerCase();
          const needle = String(inputValue).toLocaleLowerCase();
          return haystack.indexOf(needle) >= 0;
        })
      }
      loading={isFetching}
      loadingText="Načítání..."
      noOptionsText="Tomuto filtru nikdo nevyhovuje."
      clearText="Vymazat"
      openText="Otevřít"
      closeText="Zavřít"
      {...otherProps}
    />
  );
}

ProjectNameSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
};

ProjectNameSelect.defaultProps = {
  value: "",
  onChange: () => {},
  inputProps: {},
};

export default ProjectNameSelect;
