import React, { useRef, useEffect } from "react";
import { LinearProgress, Typography, Fade, Box } from "@mui/material";

function LoadingPage() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Box
      sx={{
        maxWidth: 300,
        margin: `0 auto`,
        padding: (theme) => theme.spacing(5),
        boxSizing: "border-box",
        height: "100vh",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Fade in={isMounted.current} timeout={1000}>
        <Typography variant="body1" color="textSecondary" align="center" paragraph>
          Chvíli strpení...
        </Typography>
      </Fade>
      <LinearProgress sx={{ "& .MuiLinearProgress-root": { height: 2 } }} />
    </Box>
  );
}

export default LoadingPage;
