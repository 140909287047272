import { useState, useEffect } from "react";
import useDebounce from "lib/hooks/useDebounce";

type UseEnhancedState = {
  key?: string;
  defaultValue: any;
  debounceDelay?: number;
};

export function useEnhancedState({ key, defaultValue, debounceDelay = 300 }: UseEnhancedState) {
  const localStorageValue: string | null | undefined = key ? window.localStorage.getItem(key) : undefined;

  let initialValue = localStorageValue;

  try {
    initialValue = JSON.parse(localStorageValue || "");
  } catch (error) {
    // console.log(error);
  }

  const [value, setValue] = useState(initialValue || defaultValue);

  const debouncedValue = useDebounce(value, debounceDelay);

  useEffect(() => {
    if (key) window.localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue, debouncedValue];
}

export default useEnhancedState;
