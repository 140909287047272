import api from "lib/axios";
import moment from "moment";

/**
 * Add subactivities under the parent ones.
 * @param {array} activities
 * @param {number} lvl_id
 */
function makeHiearchy(activities, lvl_id = null) {
  const currentLevel = activities.filter((a) => a.lvl_ref === lvl_id);
  currentLevel.forEach((a) => (a.subs = makeHiearchy(activities, a.lvl_id)));
  return currentLevel;
}

/**
 * Make visible only the latest activities in hiearchy.
 * @param {array} activities
 * @param {array} parentNames
 */
function parseHiearchy(activities, parentNames = []) {
  let parsed = [];
  activities.forEach((a) => {
    if (a.subs.length > 0) {
      const subs = parseHiearchy(a.subs, [...parentNames, a.nazev]);
      subs.forEach((s) => parsed.push(s));
    } else {
      a.path = parentNames;
      parsed.push(a);
    }
  });
  return parsed;
}

export default async function (uid, signal) {
  const startOfDay = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

  // Fetch running activity
  const rawActivity = await api
    .get("is_aktivity", {
      params: {
        filter: [
          ["uzivatel", "eq", uid],
          ["start", "ge", startOfDay],
          ["start", "le", endOfDay],
          ["stop", "is"],
          ["deleted", "is"],
        ],
        order: "id,desc",
      },
      signal,
    })
    .then((res) => res.data?.records || []);
  //console.log(rawActivity);
  const activity = rawActivity?.[0];

  // If we have some running activity,
  // we need to fetch reference
  let ref;
  if (activity) {
    // Fetch reference
    const rawReference = await api
      .get(Number(activity.typ) === 1 ? "is_zakladni_aktivity" : "is_projektove_aktivity", {
        params: { filter: [["id", "eq", activity.reference]] },
        signal,
      })
      .then((res) => res.data?.records || []);
    ref = rawReference?.[0];
    if (ref) {
      // In case it's project activity,
      // we have to get full path
      if (Number(activity.typ) === 2) {
        // Fetch other project activities
        const rawPA = await api
          .get("is_projektove_aktivity", {
            params: {
              filter: [
                ["proj_ref", "eq", ref.proj_ref],
                ["deleted", "is"],
              ],
            },
            signal,
          })
          .then((res) => res.data?.records || []);
        // Sort raw project activities by lvl_id
        const sortedRawPA = rawPA.sort((a, b) => (a.lvl_id < b.lvl_id ? -1 : a.lvl_id > b.lvl_id ? 1 : 0));
        // Create hiearchy
        const paHiearchy = makeHiearchy(sortedRawPA);
        // Parse hiearchu
        const paParsed = parseHiearchy(paHiearchy);
        // Find actual reference object
        ref = paParsed.find((a) => Number(a.id) === Number(activity.reference));
        if (ref && ref.proj_ref) {
          // Get referenced project
          const prj = await api
            .get(`is_projekty/${ref.proj_ref}`, { params: { columns: "number,jmeno" }, signal })
            .then((res) => res.data);
          ref.path = [`${prj.number} — ${prj.jmeno}`].concat(ref.path);
        }
      }
    }

    return { ...activity, ref };
  }
  return null;
}
