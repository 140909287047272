import React, { lazy } from "react";

// Import icons
import IconLockOpen from "@mui/icons-material/LockOpenOutlined";
import IconPersonAdd from "@mui/icons-material/PersonAddOutlined";
import IconReplay from "@mui/icons-material/ReplayOutlined";
import IconDashboard from "@mui/icons-material/DashboardOutlined";
import IconResourcing from "@mui/icons-material/TodayOutlined";
import IconProjects from "@mui/icons-material/WorkOutline";
import IconProject from "@mui/icons-material/DescriptionOutlined";
import IconUsers from "@mui/icons-material/People";
import IconUser from "@mui/icons-material/Person";
import IconClients from "@mui/icons-material/PeopleOutlined";
import IconClient from "@mui/icons-material/PersonOutlined";
import IconStats from "@mui/icons-material/ShowChartOutlined";
import IconInfo from "@mui/icons-material/InfoOutlined";
import IconSettings from "@mui/icons-material/SettingsOutlined";
import IconBugReport from "@mui/icons-material/BugReportOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ContactsIcon from "@mui/icons-material/Contacts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";

const commonOptions = {
  exact: true,
  hideInDrawer: false,
  minUserLevel: 1,
  isRestricted: true,
  isPublic: false,
};

const routes = [
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Dashboard")),
    label: "Přehled",
    path: "/",
    icon: <IconDashboard />,
    minUserLevel: 0,
  },
  {
    ...commonOptions,
    component: lazy(() => import("pages/Login")),
    label: "Přihlásit se",
    path: "/login",
    icon: <IconLockOpen />,
    hideInDrawer: true,
    minUserLevel: 0,
    isRestricted: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Register")),
    label: "Registrovat se",
    path: "/register",
    icon: <IconPersonAdd />,
    hideInDrawer: true,
    minUserLevel: 0,
    isRestricted: false,
    isPublic: true,
    disabled: true,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/ResetPassword")),
    label: "Resetovat heslo",
    path: "/reset",
    icon: <IconReplay />,
    hideInDrawer: true,
    minUserLevel: 0,
    isRestricted: false,
    isPublic: true,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Agenda")),
    label: "Agenda",
    path: "/a",
    icon: <ViewAgendaOutlinedIcon />,
    minUserLevel: 6, // only MGMT
    //onlyForUsers: [1],
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Agenda/Task")),
    label: "Úkol",
    path: "/a/:id/*",
    icon: <AssignmentTurnedInIcon />,
    hideInDrawer: true,
    onlyForUsers: [1],
    exact: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Resourcing")),
    label: "Plánování",
    path: "/r",
    icon: <IconResourcing />,
    disabled: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Projects")),
    label: "Projekty",
    path: "/p",
    icon: <IconProjects />,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Project")),
    label: "Projekt (NOVÉ)",
    path: "/p/:id/*",
    icon: <IconProject />,
    hideInDrawer: true,
    exact: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Users")),
    label: "Uživatelé",
    path: "/u",
    icon: <IconUsers />,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/User")),
    label: "Uživatel",
    path: "/u/:id/*",
    icon: <IconUser />,
    hideInDrawer: true,
    exact: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Clients")),
    label: "Klienti",
    path: "/c",
    icon: <IconClients />,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Client")),
    label: "Klient",
    path: "/c/:id/*",
    icon: <IconClient />,
    hideInDrawer: true,
    exact: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Externalists")),
    label: "Externí dodavatelé",
    path: "/ext",
    icon: <ContactsIcon />,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Externalists/ExternalCompany")),
    label: "Externí dodavatel",
    path: "/ext/:id",
    icon: <AccountBoxIcon />,
    hideInDrawer: true,
    exact: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Stats")),
    label: "Statistiky",
    path: "/s",
    icon: <IconStats />,
    minUserLevel: 6,
    disabled: false,
  },
  {
    divider: true,
    minUserLevel: 8,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Orders")),
    label: "Objednávky",
    path: "/orders",
    icon: <AssignmentOutlinedIcon />,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Invoices")),
    label: "Faktury",
    path: "/invoices",
    icon: <AssignmentTurnedInOutlinedIcon />,
    minUserLevel: 6,
    //onlyForUsers: [1],
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/IsOptions")),
    label: "Nastavení",
    path: "/options",
    icon: <IconSettings />,
    minUserLevel: 9,
    disabled: false,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/About")),
    label: "Obecné informace",
    path: "/info",
    icon: <IconInfo />,
    minUserLevel: 0,
  },
  {
    divider: true,
    minUserLevel: 0,
    hideInDrawer: true,
    disabled: true,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/UserOptions")),
    label: "Možnosti",
    path: "/uo/*",
    icon: <IconSettings />,
    hideInDrawer: true,
    minUserLevel: 0,
    disabled: true,
  },
  {
    divider: true,
    minUserLevel: 0,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/Test")),
    label: "Test",
    path: "/test",
    icon: <IconBugReport />,
    onlyForUsers: [1],
  },
  {
    divider: true,
    minUserLevel: 9,
  },
  {
    ...commonOptions,
    component: lazy(() => import("../pages/ErrorPage")),
    label: "Chyba",
    path: "/error",
    hideInDrawer: true,
    minUserLevel: 0,
    isRestricted: false,
    isPublic: true,
    exact: false,
  },
];

export default routes;
