import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

type LoadingProps = {
  text?: string;
  spaceAround?: boolean;
};

const Loading = (props: LoadingProps) => (
  <Box
    sx={(theme) => ({
      display: "flex",
      alignItems: "center",
      ...(props.spaceAround ? { padding: theme.spacing(2) } : {}),
    })}>
    <CircularProgress sx={{ marginRight: (theme) => theme.spacing(1) }} disableShrink size={24} thickness={4} />
    <Typography variant="body1">{props.text || "Načítání..."}</Typography>
  </Box>
);

export default Loading;
