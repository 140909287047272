/* eslint-disable no-unused-vars */
import { call, all, put, select, takeEvery, delay } from "redux-saga/effects";
import api from "lib/axios";
import moment from "moment";
import isSet from "lib/isSet";
import readProp from "lib/readProp";
import md5 from "lib/md5";
//import { authorize } from './user.saga'

/*
  is_aktivity             [65462] [NOT STORABLE]
  is_cenik                [  293] [STORABLE]
  is_cenik_info           [   39] [NOT STORABLE] (missing columns created, edited and deleted)
  is_cron                 [   10] [-]
  is_cron_log             [  225] [-]
  is_eventy               [ 1442] [NOT STORABLE]
  is_klient               [   90] [STORABLE]
  is_kontakt              [  470] [STORABLE]
  is_po_stavy             [    4] [STORABLE]
  is_prava                [    7] [STORABLE]
  is_programy             [   29] [STORABLE]
  is_projektove_aktivity  [22821] [NOT STORABLE]
  is_projekty             [ 4177] [NOT STORABLE]
  is_projekt_stavy        [    6] [STORABLE]
  is_svatky               [   20] [STORABLE]
  is_uzivatel             [   37] [STORABLE]
  is_uziv_nastaveni       [   37] [-]
  is_zakladni_aktivity    [   38] [STORABLE]
*/
/*
  state: {
    db: {
      _lastCheck: null,
      _isChecking: false,
      _storableTables: [
        'is_cenik',
        'is_klient',
        'is_kontakt',
        'is_po_stavy',
        'is_prava',
        'is_programy',
        'is_projekt_stavy',
        'is_svatky',
        'is_uzivatel',
        'is_zakladni_aktivity',
      ],
      _data: {},
    }
  }
*/

/**
 * Get last changes from database.
 */
function* getLastChanges() {
  //const csrf = yield select((state) => state.user.csrf)
  //const lastUpdatesRaw = yield call(API.getc, { csrf, request: 'lastChanges' })
  const lastUpdatesRaw = yield call(api.get, "", { params: { request: "lastChanges" } });
  let lastUpdates = { ...(lastUpdatesRaw?.data || {}) };

  // Convert all db timestamps to milliseconds
  yield all([...Object.entries(lastUpdates).map(([table, date]) => (lastUpdates[table] = moment(date).valueOf()))]);

  return lastUpdates;
}

/**
 * Get all data from given table.
 *
 * @param {string} tableName Table name
 */
function* getTableData(tableName) {
  try {
    //const state = yield select()
    //const { csrf } = state.user
    // Get new data from database
    //const data = yield call(API.get, tableName, { csrf }, true)
    const result = yield call(api.get, tableName);
    // Return object in format { tableName: arrayWithRecords }
    return { [tableName]: [...(result.data?.records || [])] };
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    return false;
  }
}

/**
 * For each storable tables, re-fetch entire table if something changed.
 *
 * @param {*} previous Object with previous lastChanges sate
 * @param {*} next Object with new lastChanges state
 */
function* checkData(previous, next) {
  const { _data, _storableTables } = yield select((state) => state.db);
  let update = false;
  let data = { ..._data };

  yield all([
    // Go trough all storable tables and update/load them
    ..._storableTables.map(function* (tableName) {
      // Proceed only in case the last create/update/delete time
      // is not equal or the previous value is not available
      if (
        !readProp(() => previous[tableName]) ||
        (readProp(() => next[tableName]) && previous[tableName] !== next[tableName])
      ) {
        // Get new data (entire table)
        const updated = yield call(getTableData, tableName);
        if (updated) {
          // OK, we have some update
          data = { ...data, ...updated };
          update = true;
        }
      }
      return true;
    }),
  ]);

  return update ? data : false;
}

/**
 * Check and refresh local data from database continuously.
 */
// eslint-disable-next-line
function* dbCheck() {
  try {
    const dbCheckDelay = 5;
    const state = yield select();
    const { user, db } = state;
    const lastCheck = moment(db._lastCheck);
    const lastChanges = db._lastChanges;

    if (readProp(() => user.csrf)) {
      // Verify that the last check is not null and that it's the right time to checking (delay is off)
      if (lastCheck.isValid() && lastCheck.valueOf() <= moment().subtract(dbCheckDelay, "seconds").valueOf()) {
        // Get latest changes
        const newLastChanges = yield call(getLastChanges);
        // Update state
        yield put({ type: "DB_UPDATED_LASTCHANGES", lastChanges: newLastChanges });
        // Check data
        const data = yield call(checkData, lastChanges, newLastChanges);
        if (data) {
          //console.log(data)
          yield put({ type: "DB_UPDATED_DATA", data });
        } else yield put({ type: "DB_CHECK_COMPLETED" });
      }

      // Next (delayed) check
      yield delay(dbCheckDelay * 1000);
      yield put({ type: "DB_CHECK_REQUESTED" });
    }
  } catch (error) {
    yield put({ type: "DB_CHECK_FAILED", error });

    // Store error
    let get_error = localStorage.getItem("get_error");
    if (isSet(get_error)) get_error += "\n";
    else get_error = "";
    get_error += "Chyba během zjišťování posledních změn v databázi! [db.saga/dbCheck]";
    localStorage.setItem("get_error", get_error);
    // eslint-disable-next-line no-console
    console.log("Chyba během zjišťování posledních změn v databázi! [db.saga/dbCheck]");

    // Next (delayed) check
    yield delay(15 * 1000);
    yield put({ type: "DB_CHECK_REQUESTED" });
  }
}

/**
 * Set current milliseconds to lastCheck value and re-fetch data.
 * If table parameter is specified, lastChange of that table is updated as well.
 *
 * @param {{ table: string = null}} param0
 */
// eslint-disable-next-line
function* resetLastChange({ table = null }) {
  // For specific table as well
  if (table) {
    yield put({ type: "DB_RESET_LASTCHANGE_TABLE", table, lastChange: moment().valueOf() });
  }
  // Reset state. db. _lastCheck
  yield put({ type: "DB_RESET_LASTCHECK", lastCheck: moment().valueOf() });
  // Trigger database check
  yield put({ type: "DB_CHECK_REQUESTED" });
}

/**
 * Store data in database.
 *
 * @param {Object} data
 */
function* storeData(data) {
  //console.log(data) // eslint-disable-line no-console
  if (data.key) {
    yield put({ ...data, type: "DB_STORE_DATA" });
  }
}

/**
 * Clear local date based on given key.
 * @param {{ key: string|number}} param0 Object containing key to clear
 */
function* clearStoredData({ key }) {
  yield console.log("Call of non-implemented Saga function!", key); // eslint-disable-line no-console
  //yield put({ type: 'DB_CLEAR_STORED_DATA', key })
}

function* verifyToken() {
  try {
    // Update state if invalidation is requested
    const requestedInvalidation = sessionStorage.getItem("invalidateToken");
    const autoLogin = localStorage.getItem("autoLogin") || sessionStorage.getItem("autoLogin");
    if (requestedInvalidation === "REQUESTED" && autoLogin) {
      //console.log('Token is not valid! Getting new token... [db.saga/verifyToken]')
      sessionStorage.setItem("invalidateToken", "PROCESSING");
      yield put({ type: "DB_TOKEN_INVALID" });
      // Get curent user and password
      const email = sessionStorage.getItem("email");
      const password = sessionStorage.getItem("password");
      // Login
      yield put({ type: "USER_LOGIN_REQUESTED", email, password: md5(password) });
      //yield call(authorize, email, password)
      // If succed, this will be called from user saga: USER_LOGIN_SUCCEEDED
    } else {
      const last_login = sessionStorage.getItem("last_login");
      const requireLastLoginUpdate = last_login ? moment().isAfter(moment(last_login).add(60, "seconds")) : false;
      if (requestedInvalidation === "NONE" && (requireLastLoginUpdate || !last_login)) {
        yield put({ type: "USER_UPDATE_LAST_LOGIN" });
      }
      //console.log("Repeat token verification in 5sec...");
      yield delay(5 * 1000);
      yield put({ type: "DB_TOKEN_VERIFICATION" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* userLoginSucceeded() {
  //console.log('Token is OK.')
  sessionStorage.setItem("invalidateToken", "NONE");
  yield put({ type: "DB_TOKEN_UPDATED" });
  //console.log('Login succeeded, start token verification in 5sec...')
  yield delay(5 * 1000);
  yield put({ type: "DB_TOKEN_VERIFICATION" });
}

function* userLoginFailed() {
  yield put({ type: "DB_TOKEN_INVALID" });
}

/**
 * Root Saga generator for database relations.
 */
export function* rootDB() {
  // Triggers reset
  yield takeEvery("USER_LOGIN_SUCCEEDED", userLoginSucceeded);
  yield takeEvery("USER_LOGIN_FAILED", userLoginFailed);
  yield takeEvery("DB_INVALIDATE_TOKEN", userLoginFailed);
  //yield takeEvery('USER_LOGIN_SUCCEEDED', resetLastChange)
  //yield takeEvery('DB_RESET_LAST_CHECK_REQUESTED', resetLastChange)
  // Continuous update
  //yield takeEvery('DB_CHECK_REQUESTED', dbCheck)
  // Continuous token check
  yield takeEvery("DB_TOKEN_VERIFICATION", verifyToken);
  // Handle store data request
  yield takeEvery("DB_STORE_DATA_REQUESTED", storeData);
  // handle clear stored data request
  yield takeEvery("DB_CLEAR_STORED_DATA_REQUESTED", clearStoredData);
}
