import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@tanstack/react-query";
import { api } from "lib/axios";
import ListboxComponent from "./ListboxComponent";
import renderGroup from "./renderGroup";

function ContactSelect({ value, onChange, inputProps, ...otherProps }) {
  const [selected, setSelected] = useState(null);

  const enhancedContact = (contact) => {
    const _isDisabled = Boolean(contact.deleted) || Boolean(contact?.klient_id?.deleted);
    return {
      ...contact,
      _display: `${contact.jmeno} ${contact.prijmeni}`,
      _groupName: contact?.klient_id?.name,
      _searchString: String(
        `${contact.jmeno} ${contact.prijmeni} ${contact.email} ${contact?.klient_id?.name}`
      ).toLocaleLowerCase(),
      _isDisabled,
    };
  };

  const { data: contacts, isFetching } = useQuery({
    queryKey: ["contact-select"],
    queryFn: ({ signal }) =>
      api.get("is_kontakt", {
        params: {
          join: "is_klient",
          include: {
            is_kontakt: ["id", "jmeno", "prijmeni", "email", "deleted"],
            is_klient: ["id", "name", "deleted"],
          },
        },
        signal,
      }),
    select: (res) => res.data?.records || [],
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let selected = null;
    // Select contact
    if (value && contacts?.length > 0) {
      const found = contacts.find((u) => Number(u.id) === Number(value));
      if (found) selected = enhancedContact(found);
    }
    // Update internal state
    setSelected(selected);
  }, [contacts, value]);

  const data = useMemo(() => {
    let memorized = [];

    if (contacts?.length > 0) {
      memorized = contacts.map((contact) => enhancedContact(contact));
    }
    // Return sorted data
    return memorized.sort((a, b) => {
      const aa = `${a._groupName} ${a._isDisabled ? 1 : 0} ${a._display}`;
      const bb = `${b._groupName} ${b._isDisabled ? 1 : 0} ${b._display}`;
      return aa < bb ? -1 : aa > bb ? 1 : 0;
    });
  }, [contacts]);

  /**
   * Handle autocomplete change.
   * @param {Event} event Provided Event
   * @param {object} selectedObject Selected contact object
   */
  const handleChange = (event, selectedObject) => {
    event.preventDefault();

    const selected = selectedObject ? { ...selectedObject } : null;
    // Update local state
    setSelected(selected);
    // Notify parent about changes
    onChange(selected);
  };

  return (
    <Autocomplete
      //id="virtualized-contact-select"
      fullWidth
      disableListWrap
      //autoSelect
      sx={{
        boxSizing: "border-box",
        "& ul": {
          padding: 0,
          margin: 0,
        },
      }}
      ListboxComponent={ListboxComponent}
      value={selected}
      onChange={handleChange}
      options={data || []}
      groupBy={(option) => option._groupName}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Kontakt"
          placeholder="Začni psát a/nebo vyber..."
          {...inputProps}
        />
      )}
      renderOption={(props, option) => (
        <Typography noWrap {...props}>
          {option._display}
        </Typography>
      )}
      isOptionEqualToValue={(option, selected) => Number(option.id) === Number(selected.id)}
      getOptionLabel={(option) => option?._display}
      getOptionDisabled={(option) => option._isDisabled}
      filterOptions={(options, { inputValue }) => {
        const str = String(inputValue).toLocaleLowerCase();
        return options.filter((option) => String(option._searchString).indexOf(str) >= 0);
      }}
      loading={isFetching}
      loadingText="Načítání..."
      noOptionsText="Tomuto filtru nikdo nevyhovuje."
      clearText="Vymazat"
      openText="Otevřít"
      closeText="Zavřít"
      disableClearable
      {...otherProps}
    />
  );
}

ContactSelect.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
};

ContactSelect.defaultProps = {
  value: null,
  onChange: () => {},
  inputProps: {},
};

export default ContactSelect;
