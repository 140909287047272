import React, { Suspense, useMemo } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Layout from "components/Layout";
import Snacks from "components/Snacks";
import ErrorBoundaryPage from "components/ErrorBoundaryPage";
import LoadingPage from "pages/LoadingPage";
import routes from "lib/routes";
import useAuth from "lib/hooks/useAuth";

function ElementWrapper({ path, isRestricted, isPublic }) {
  const { isLogged } = useAuth();
  const location = useLocation();

  const allow = useMemo(() => {
    // Allow only if logged in
    if (isRestricted && isLogged) return true;
    // Allow only if NOT logged in
    if (!isRestricted && !isLogged) return true;
    // If public, allow always
    if (isPublic) return true;
    // Do not render if not verified
    return false;
  }, [isLogged, isRestricted, isPublic]);

  let redirectTo = isRestricted ? "/login" : "/";

  // Redirect from login to previous page when user is logged
  if (!allow && path === "/login") {
    // pathname
    const fullPath = location?.state?.from;
    if (fullPath.length) redirectTo = fullPath;
    // searchParams
    const search = location?.state?.search;
    if (search.length) redirectTo += search;
  }

  return allow ? (
    <Suspense fallback={<LoadingPage />}>
      <ErrorBoundaryPage>
        <Outlet />
      </ErrorBoundaryPage>
    </Suspense>
  ) : (
    <Navigate to={redirectTo} state={{ from: location.pathname, search: location.search }} replace />
  );
}

ElementWrapper.propTypes = {
  path: PropTypes.string,
  isRestricted: PropTypes.bool,
  isPublic: PropTypes.bool,
};

function Router() {
  const constructRoutes = useMemo(() => {
    let list = [];
    // Construct provided routes
    routes.forEach(({ path, component: LazyComponent, isRestricted, isPublic, divider, disabled }) => {
      if (!divider && !disabled) {
        list.push(
          <Route key={path} path={path} element={<ElementWrapper {...{ path, isRestricted, isPublic }} />}>
            <Route index element={<LazyComponent />} />
          </Route>
        );
      }
    });
    // Redirect to error route if there is no route match
    list.push(<Route key="/error-redirect" path="*" element={<Navigate to="/error" />} />);
    // Return list of routes
    return list;
  }, []);

  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Layout />}>
          {constructRoutes}
        </Route>
      </Routes>
      <Snacks />
    </BrowserRouter>
  );
}

export default Router;
