import { useQuery } from "@tanstack/react-query";
import api from "lib/axios";

/**
 * Custom hook for clients.
 * @param {Object} options Custom query options
 */
export function useClients(options = {}) {
  const { data: clients, ...rest } = useQuery({
    queryKey: ["clients"],
    queryFn: ({ signal }) => api.get("is_klient", { signal }),
    select: (res) => res.data?.records || [],
    refetchOnWindowFocus: false,
    ...options,
  });
  return { clients, ...rest };
}
