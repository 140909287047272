import { useQuery } from "@tanstack/react-query";
import api from "lib/axios";

/**
 * Custom hook for user rights.
 * @param {Object} options Custom query options
 */
export function useUserRights(options = {}) {
  const { data: userRights, ...rest } = useQuery({
    queryKey: ["user-rights"],
    queryFn: ({ signal }) => api.get("is_prava", { signal }),
    select: (res) => res.data?.records || [],
    refetchOnWindowFocus: false,
    ...options,
  });
  return { userRights, ...rest };
}
