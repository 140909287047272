import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { amber, green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function CustomSnackbarContent(props) {
  const { forwardedRef, sx, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  const action = onClose
    ? [
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose} size="large">
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>,
      ]
    : undefined;

  const variantStyle = useMemo(() => {
    const v = {};

    if (variant === "success") {
      v.backgroundColor = green[600];
    }

    if (variant === "error") {
      v.backgroundColor = (theme) => theme.palette.error.dark;
      v.color = (theme) => theme.palette.error.contrastText;
    }

    if (variant === "info") {
      v.backgroundColor = (theme) => theme.palette.primary.main;
      v.color = (theme) => theme.palette.primary.contrastText;
    }

    if (variant === "warning") {
      v.backgroundColor = amber[700];
    }

    return v;
  }, [variant]);

  return (
    <SnackbarContent
      ref={forwardedRef}
      sx={{ ...sx, ...variantStyle }}
      aria-describedby="client-snackbar"
      message={
        <Box
          component="span"
          id="client-snackbar"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon sx={{ fontSize: 20, opacity: 0.9, marginRight: (theme) => theme.spacing(1) }} />
          {message}
        </Box>
      }
      action={action}
      {...other}
    />
  );
}

CustomSnackbarContent.propTypes = {
  sx: PropTypes.object,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
  forwardedRef: PropTypes.any,
};

const CustomSnackbarContentWithRef = React.forwardRef((props, ref) => (
  <CustomSnackbarContent {...props} forwardedRef={ref} />
));
CustomSnackbarContentWithRef.displayName = "CustomSnackbarContent";

export default CustomSnackbarContentWithRef;
