import { put, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

/**
 * Add new snack to the redux's state (queue).
 * @param {string} message Message to display.
 * @param {number} [delay=null] Time in milliseconds when notification diappear.
 */
function* addSnack({ message = 'Ahoj :)', delay = 5000, action = null }) {
  yield put({
    type: 'SNACK_ADD_REQUESTED',
    data: {
      key: moment().valueOf(),
      message,
      delay,
      action,
    },
  })
}

/**
 * Remove the first snack from queue.
 */
function* removeSnack() {
  yield put({ type: 'SNACK_REMOVE_REQUESTED' })
}

/**
 * Root snacks saga, which will watch all actions.
 */
export function* snacksSaga() {
  yield takeEvery('SNACK_ADD', addSnack)
  yield takeEvery('SNACK_REMOVE', removeSnack)
}
