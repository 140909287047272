import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Paper,
  Typography,
  Button,
  Divider,
  List,
  ListItemText,
  ListSubheader,
  Box,
  ListItemButton,
} from "@mui/material";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import fetchRunningActivity from "lib/api/fetchRunningActivity";
import fetchLastActivities from "lib/api/fetchLastActivities";
import api from "lib/axios";
import moment from "moment";

function RunningActivityWidget() {
  // Access the client
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const addSnack = (message = null, delay = null) => dispatch({ type: "SNACK_ADD", message, delay });
  const [open, setOpen] = useState(false);

  // Get running activity, automatically recheck every 15 seconds
  const { data: activity } = useQuery({
    queryKey: ["running-activity", user?.id],
    // TODO: implement signal
    queryFn: ({ queryKey }) => fetchRunningActivity(queryKey[1]),
    refetchInterval: 15 * 1000,
    enabled: Boolean(user?.id),
  });

  // Get get 5 latest activities
  const { data: lastActivities } = useQuery({
    queryKey: ["last-activities", user?.id],
    // TODO: implement signal
    queryFn: ({ queryKey }) => fetchLastActivities(queryKey[1]),
    enabled: Boolean(user?.id) && open,
  });

  // Stop activity
  const stopActivity = useMutation({
    mutationFn: ({ id }) => {
      const now = moment().format("YYYY-MM-DD HH:mm:ss");
      return api.put(`is_aktivity/${id}`, { edited: now, edited_user: user.id, stop: now }).then((res) => res.data);
    },
    onSuccess: () => {
      addSnack(`Aktivita byla úspěšně zastavena.`, 1500);
    },
    onError: () => {
      addSnack(`Chyba při zastavování aktivity!`, 10000);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["running-activity", user.id] }, { exact: true });
      queryClient.invalidateQueries({ queryKey: ["last-activities", user.id] }, { exact: true });
    },
  });

  /**
   * Stop running activity.
   * @param {MouseEvent} event Mouse event
   * @param {number} id Activity ID
   */
  const handleStopActivity = (event, id) => {
    event.preventDefault();
    // Set "temporary" blank data
    queryClient.setQueryData(["running-activity", user.id], null);
    // Stop running activity
    stopActivity.mutate({ id });
  };

  // Start activity mutation and re-fetch running activity when done
  const startActivity = useMutation({
    mutationFn: ({ options }) =>
      api.get("", { params: { request: "startActivity", ...options } }).then((res) => res.data),
    onSuccess: () => {
      addSnack(`Aktivita byla úspěšně zastavena.`, 1500);
    },
    onError: () => {
      addSnack(`Chyba při zastavování aktivity!`, 10000);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["running-activity", user.id] }, { exact: true });
      queryClient.invalidateQueries({ queryKey: ["last-activities", user.id] }, { exact: true });
    },
  });

  /**
   * Stop running activity.
   * @param {MouseEvent} event Mouse event
   * @param {number} id Activity ID
   */
  const handleStartActivity = (event, options) => {
    event.preventDefault();
    // Set "temporary" data
    // (Blank object means that we'll be showing a new acitivity.)
    queryClient.setQueryData(["running-activity", user.id], {});
    // Start activity
    //startActivity({ fromUser: user.id, forUser: user.id, reference, type: 1 });
    startActivity.mutate({ options });
  };

  return (
    <Box sx={{ position: "relative", height: 50 }}>
      <Paper
        variant="outlined"
        sx={(theme) => ({
          transition: "all 0.5s",
          transitionTimingFunction: theme.transitions.easing.easyInOut,
          position: "absolute",
          top: 0,
          right: 0,
          overflow: "hidden",
          ...(open
            ? {
                width: theme.breakpoints.values.sm,
                maxWidth: theme.breakpoints.values.sm,
                maxHeight: 500,
                boxShadow: theme.shadows[10],
              }
            : {
                width: 250,
                maxWidth: 250,
                maxHeight: 50,
              }),
        })}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}>
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
            padding: (theme) => theme.spacing(1.5),
          }}>
          <Box
            sx={(theme) => ({
              transition: "all 0.5s",
              transitionTimingFunction: theme.transitions.easing.easyInOut,
              ...(open && { padding: "6px" }),
            })}>
            <TimelapseIcon
              color={activity?.id ? "primary" : "secondary"}
              sx={(theme) => ({
                transition: "all 0.5s",
                transitionTimingFunction: theme.transitions.easing.easyInOut,
                transformOrigin: "center center",
                ...(open && {
                  transform: "scale(1.5) rotate(360deg)",
                }),
              })}
            />
          </Box>
          <Box
            sx={(theme) => ({
              transition: "all 0.5s",
              transitionTimingFunction: theme.transitions.easing.easyInOut,
              display: "flex",
              flexDirection: "column",
              paddingLeft: theme.spacing(1.5),
              overflow: "hidden",
              flex: 1,
              ...(open && {
                paddingTop: theme.spacing(1),
              }),
            })}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                flexShrink: 1,
              }}>
              <Typography
                variant="body1"
                color="textPrimary"
                sx={(theme) => ({
                  flex: "initial",
                  flexShrink: 1,
                  flexGrow: 0,
                  flexBasis: "0%",
                  transition: "all 0.5s",
                  transitionTimingFunction: theme.transitions.easing.easyInOut,
                  ...(open &&
                    activity?.ref?.path?.length && {
                      flexGrow: 1,
                      flexBasis: "max-content",
                      paddingRight: theme.spacing(1),
                    }),
                })}
                noWrap>
                {activity?.ref?.path ? activity.ref.path.map((name, i) => <span key={i}>{`${name} » `}</span>) : ""}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                sx={{
                  fontWeight: (theme) => theme.typography.fontWeightBold,
                  maxWidth: "100%",
                  flexShrink: 1,
                  flexGrow: 1,
                  flexBasis: "fill",
                }}
                noWrap>
                {typeof activity === "object" && activity !== null && !Object.keys(activity).length
                  ? "Spouštění aktivity..."
                  : activity?.ref?.nazev
                  ? activity.ref.nazev
                  : "Žádná aktivita"}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                transition: "all 0.5s",
                transitionTimingFunction: theme.transitions.easing.easyInOut,
                overflow: "hidden",
                maxHeight: 100,
                opacity: 1,
                ...((!open || !activity?.start) && {
                  maxHeight: 0,
                  opacity: 0,
                }),
              })}>
              <Typography variant="body2" color="textSecondary" noWrap>
                {activity?.start && `Přibližně ${moment(activity.start).fromNow(true)}`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              transition: "all 0.5s",
              transitionTimingFunction: theme.transitions.easing.easyInOut,
              overflow: "hidden",
              paddingLeft: theme.spacing(2),
              maxWidth: 300,
              maxHeight: 50,
              opacity: 1,
              ...((!open || !activity?.id) && {
                maxWidth: 0,
                maxHeight: 0,
                opacity: 0,
              }),
            })}>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!activity?.id}
              onClick={(event) => handleStopActivity(event, activity.id)}>
              Zastavit
            </Button>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            transition: "all 0.5s",
            transitionTimingFunction: theme.transitions.easing.easyInOut,
            overflow: "hidden",
            maxHeight: 500,
            scrollY: "auto",
            ...((!open || !lastActivities?.length) && {
              maxHeight: 0,
            }),
          })}>
          <Divider />
          <List subheader={<ListSubheader>Předchozí aktivity</ListSubheader>}>
            {lastActivities &&
              lastActivities.map((a, index) => (
                <ListItemButton
                  key={index}
                  onClick={(event) =>
                    handleStartActivity(event, {
                      fromUser: user.id,
                      forUser: user.id,
                      reference: a.reference,
                      type: a.typ,
                    })
                  }>
                  <ListItemText
                    disableTypography
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                      "& > span": {
                        marginRight: theme.spacing(1.5),
                        display: "flex",
                        alignItems: "center",
                      },
                    })}>
                    <span>
                      <TimelapseIcon color="action" />
                    </span>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      noWrap
                      sx={{ flexShrink: 1, paddingRight: a?.ref?.path?.length ? 0.75 : 0 }}>
                      {a?.ref?.path ? a.ref.path.map((name, i) => <span key={i}>{`${name} » `}</span>) : ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      noWrap
                      sx={{
                        fontWeight: (theme) => theme.typography.fontWeightBold,
                        maxWidth: "100%",
                        flexShrink: 1,
                        flexGrow: 1,
                        //flexBasis: "min-content",
                      }}>
                      {a?.ref?.nazev || "Neznámá aktivita"}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Paper>
    </Box>
  );
}

export default RunningActivityWidget;
