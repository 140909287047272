import React from "react";
import ListSubheader from "@mui/material/ListSubheader";

function renderGroup(params) {
  return [
    <ListSubheader key={params.key} component="div">
      {params.group}
    </ListSubheader>,
    params.children,
  ];
}

export default renderGroup;
