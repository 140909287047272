import { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";

export function useAuth() {
  const user = useSelector(({ user }) => user || { _fetching: false, _error: null }, shallowEqual);
  const isLogged = useMemo(() => Boolean(user?.csrf), [user?.csrf]);

  return { isLogged, user };
}

export default useAuth;
