import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(/*error*/) {
    //console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "150px 20px",
            width: "100%",
          }}
        >
          <Typography variant="h1" color="error">
            Ooops!
          </Typography>
          <Typography variant="h2" color="textSecondary" paragraph>
            Něco se pokazilo.
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph>
            Abych mohl lépe odladit případné chyby, zobrazuji tuto informaci.
          </Typography>
          <Typography variant="body1" color="textPrimary" paragraph>
            Chyba je vypsaná v konzoli (F12), prosím pošlete mi ji třeba do chatu. Díky za spolupráci, Mara.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
