/**
 * This function checks if the application is online by attempting to fetch the URL and returns a boolean value.
 *
 * It checks if the browser is offline and then sends a HEAD request to the application's origin URL to check
 * if the server is reachable. If the request is successful and returns a status code of 200-299,
 * the function returns `true`. Otherwise, it returns `false`.
 */
export const isAppOnline = async () => {
  if (!window.navigator.onLine) return false;
  const url = new URL(window.location.origin);
  try {
    const response = await fetch(url.toString(), { method: "HEAD", cache: "no-store" });
    return response.ok;
  } catch {
    return false;
  }
};

export default isAppOnline;
