import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
//import thunkMiddleware from "redux-thunk"
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas/index'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

export default createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(sagas)
