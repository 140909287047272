const defaultState = {
  _fetching: false,
  _error: null,
  _updating: false,
};

export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    case "USER_LOGIN_REQUESTED":
      return { ...state, ...{ _fetching: true, _error: null } };

    case "USER_LOGIN_SUCCEEDED": {
      // Set a new CSRF token for session storage
      sessionStorage.setItem("csrf", action?.user?.csrf);
      sessionStorage.setItem("uid", action?.user?.id);

      return { ...state, ...action.user, ...{ _fetching: false, _error: null } };
    }

    case "USER_LOGIN_FAILED": {
      // Remove CSRF token from session storage
      sessionStorage.removeItem("csrf");

      //return { ...state, ...{ _fetching: false, _error: action.error } }
      return { ...defaultState, ...{ _fetching: false, _error: action.error } };
    }

    case "USER_UPDATE_REQUESTED":
      return { ...state, ...{ _updating: true } };

    case "USER_UPDATE_SUCCEEDED": {
      // If needed, update CSRF token in session storage
      const newCSRF = action?.user?.csrf;
      if (newCSRF !== sessionStorage.getItem("csrf")) sessionStorage.setItem("csrf", newCSRF);

      return { ...state, ...action.user, ...{ _updating: false } };
    }

    case "USER_UPDATE_FAILED":
      return { ...state, ...{ _updating: false, _error: action.error } };

    case "USER_UPDATED": {
      // If needed, update CSRF token in session storage
      const newCSRF = action?.user?.csrf;
      if (newCSRF !== sessionStorage.getItem("csrf")) sessionStorage.setItem("csrf", newCSRF);

      return { ...state, ...action.user };
    }

    case "USER_LEVEL_CHANGE_REQUESTED": {
      return { ...state, ...action.payload };
    }

    case "USER_LOGOUT": {
      // Remove CSRF token from session storage
      sessionStorage.removeItem("csrf");

      return { ...defaultState };
    }

    default:
      return state;
  }
};
