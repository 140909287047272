import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Menu, MenuItem, ListItemIcon, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import ContactDialog from "pages/Client/Contact.dialog";
import CreateProjectDialog from "pages/CreateProject";

function AddMenu({ user }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState();
  const [openNewProject, setOpenNewProject] = useState(false);
  const [openNewContact, setOpenNewContact] = useState(false);

  return (
    <>
      <Tooltip title="Vytvořit">
        <span>
          <IconButton
            onClick={(e) => setOpen(e.currentTarget)}
            aria-controls="add-menu"
            aria-haspopup="true"
            size="large">
            <AddBoxIcon color="inherit" />
          </IconButton>
        </span>
      </Tooltip>
      <Menu id="add-menu" open={!!open} onClose={() => setOpen()} anchorEl={open} keepMounted>
        <MenuItem
          onClick={() => {
            setOpen();
            setOpenNewProject(true);
          }}>
          <ListItemIcon>
            <CreateNewFolderIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Nový projekt/analýza
          </Typography>
        </MenuItem>
        {user.id === 0 && (
          <MenuItem
            onClick={() => {
              setOpen();
            }}
            disabled={user.id !== 1}>
            <ListItemIcon>
              <AssignmentTurnedInIcon color="inherit" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Nový úkol
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpen();
            navigate(`/u/${user.id}?tab=absences`);
          }}
          //disabled={user.id !== 1}
        >
          <ListItemIcon>
            <FlightTakeoffIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Nová absence
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen();
            setOpenNewContact(true);
          }}
          //disabled={user.id !== 1}
        >
          <ListItemIcon>
            <PersonAddOutlinedIcon color="inherit" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Nový kontakt
          </Typography>
        </MenuItem>
      </Menu>
      <CreateProjectDialog open={openNewProject} onClose={() => setOpenNewProject(false)} />
      <ContactDialog open={openNewContact} onClose={() => setOpenNewContact(false)} />
    </>
  );
}

AddMenu.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(AddMenu);
