import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, useMediaQuery } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useUserRights } from "lib/hooks/useUserRights";
import Loading from "components/Loading";

function ViewLevelDialog({ open, onClose, user, changeUserLevel }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [userLevel, setUserLevel] = useState(9);

  // Get user rights
  const { userRights, status } = useUserRights();

  useEffect(() => {
    if (open) setUserLevel(user.userLevel);
  }, [open, user.userLevel]);

  /**
   * Change user level and close dialog.
   */
  const applyChange = () => {
    changeUserLevel(userLevel);
    onClose();
  };

  const sameLevel = useMemo(() => {
    return user?.userLevel === userLevel;
  }, [user, userLevel]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="view-level-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}>
      <DialogTitle id="view-level-dialog-title">Změna oprávnění</DialogTitle>
      <DialogContent>
        {status === "pending" && userRights?.length === 0 && <Loading text="Načítání práv..." />}
        {status !== "pending" && userRights?.length > 0 && (
          <React.Fragment>
            <DialogContentText>Tato změna není permanentní.</DialogContentText>
            <form noValidate autoComplete="off" onSubmit={(event) => event.preventDefault()}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="rights-label">Oprávnění</InputLabel>
                <Select
                  id="right"
                  labelId="rights-label"
                  label="Oprávnění"
                  value={userLevel}
                  onChange={(event) => setUserLevel(event.target.value)}
                  fullWidth>
                  {userRights
                    .sort((a, b) => (a.uroven > b.uroven ? 1 : a.uroven < b.uroven ? -1 : 0))
                    .map((r) => (
                      <MenuItem key={r.id} value={r.uroven}>
                        {r.nazev}
                        {user.opravneni === r.id && " (původní)"}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </form>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()}>
          Zrušit
        </Button>
        <Button color="primary" onClick={() => applyChange()} disabled={sameLevel}>
          Použít
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ViewLevelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  changeUserLevel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  changeUserLevel: (userLevel) => dispatch({ type: "USER_LEVEL_CHANGE", payload: { userLevel } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLevelDialog);
