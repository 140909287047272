export default {
  weekdays: {
    standalone: 'neděle_pondělí_úterý_středa_čtvrtek_pátek_sobota'.split('_'),
    format: 'neděle_pondělí_úterý_středy_čtvrtka_pátku_soboty'.split('_'),
    isFormat: /\s?dddd/,
  },
  months: {
    standalone: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split(
      '_',
    ),
    format: 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split(
      '_',
    ),
    isFormat: /D.\sMMMM/,
  },
  durationLabelsStandard: {
    S: 'milisekunda',
    SS: 'milisekund',
    s: 'sekunda',
    ss: 'sekund',
    m: 'minuta',
    mm: 'minut',
    h: 'hodina',
    hh: 'hodin',
    d: 'den',
    dd: 'dní',
    w: 'týden',
    ww: 'týdnů',
    M: 'mesíc',
    MM: 'měsíců',
    y: 'rok',
    yy: 'roků',
  },
  durationLabelsShort: {
    S: 'ms',
    SS: 'ms',
    s: 's',
    ss: 's',
    m: 'min',
    mm: 'min',
    h: 'hod',
    hh: 'hod',
    d: 'd',
    dd: 'd',
    w: 't',
    ww: 't',
    M: 'm',
    MM: 'm',
    y: 'r',
    yy: 'r',
  },
  durationTimeTemplates: {
    HMS: 'h:mm:ss',
    HM: 'h:mm',
    MS: 'm:ss',
  },
  durationLabelTypes: [{ type: 'standard', string: '__' }, { type: 'short', string: '_' }],
  durationPluralKey: (token, integerValue, decimalValue) =>
    integerValue === 1 && decimalValue === null ? token : token + token,
}
