import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import Collapse from "@mui/material/Collapse";
import Loading from "components/Loading";
import moment from "moment";
import readProp from "lib/readProp";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "lib/axios";
import ClientSelect from "components/ClientSelect";
import Box from "@mui/material/Box";

function ContactDialog({ open, user, inputData, clientID, addSnack, onClose }) {
  // Access the client
  const queryClient = useQueryClient();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [jmeno, setJmeno] = useState("");
  const [prijmeni, setPrijmeni] = useState("");
  const [pozice, setPozice] = useState("");
  const [email, setEmail] = useState("");
  const [telefon, setTelefon] = useState("");
  const [client, setClient] = useState(null);

  // Create contact mutation
  const createContact = useMutation({
    mutationFn: ({ data }) =>
      api.post("is_kontakt", { ...data, created: moment().format("YYYY-MM-DD HH:mm:ss") }).then((res) => res.data),
    onSuccess: () => {
      addSnack("Kontakt je úspěšně vytvořený.", 1500);
      handleClose(true);
    },
    onError: (error) => {
      console.log(error);
      addSnack("Kontakt se nezdařilo vytvořit!", 10000);
    },
  });

  /**
   * Create a new contact in DB and close dialog.
   */
  const handleCreateContact = () => {
    createContact.mutate({
      data: {
        klient_id: client.id,
        jmeno,
        prijmeni,
        pozice,
        email,
        telefon,
        created_user: user.id,
      },
    });
  };

  // Update contact mutation
  const updateContact = useMutation({
    mutationFn: ({ id, data }) =>
      api.put(`is_kontakt/${id}`, { ...data, edited: moment().format("YYYY-MM-DD HH:mm:ss") }).then((res) => res.data),
    onSuccess: () => {
      addSnack("Kontakt je upravený.", 1500);
      handleClose(true);
    },
    onError: (error) => {
      addSnack("Úprava kontaktu se nezdařila!", 10000);
      console.log(error);
    },
  });

  /**
   * Update contact in DB and close dialog.
   */
  const handleUpdateContact = () => {
    updateContact.mutate({
      id: inputData.id,
      data: {
        klient_id: client?.id,
        jmeno,
        prijmeni,
        pozice,
        email,
        telefon,
        edited_user: user.id,
      },
    });
  };

  /**
   * Fucntion is triggered when dialog is opened.
   * It update state with input data.
   */
  const onEnter = () => {
    if (inputData?.id) {
      setJmeno(inputData?.jmeno || "");
      setPrijmeni(inputData?.prijmeni || "");
      setPozice(inputData?.pozice || "");
      setEmail(inputData?.email || "");
      setTelefon(inputData?.telefon || "");
      setClient({ id: inputData?.klient_id });
    } else {
      setJmeno("");
      setPrijmeni("");
      setPozice("");
      setEmail("");
      setTelefon("");
      setClient(inputData?.klient_id ? { id: inputData.klient_id } : null);
    }
  };

  /**
   * Close dialog.
   * @param {boolean} change Specifies if change was done
   */
  const handleClose = (change = false) => {
    if (change) {
      queryClient.invalidateQueries("contacts");
      queryClient.invalidateQueries("contact-select");
    }
    onClose(change);
  };

  /**
   * Some fields cannot be blank.
   * @param {array} fields Array of values for verification.
   */
  const isValid = (fields) => {
    let valid = true;
    fields.forEach((f) => {
      if (!f || String(f).length === 0) valid = false;
    });
    return valid;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-dialog-title"
      TransitionProps={{
        onEnter,
      }}>
      <DialogTitle id="contact-dialog-title">
        {readProp(() => inputData.id) ? "Úprava" : "Vytvoření"} kontaktní osoby
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={createContact.status === "pending"}>
          <Loading text="Vytváření kontaktní osoby..." />
        </Collapse>
        <Collapse in={updateContact.status === "pending"}>
          <Loading text="Úprava kontaktní osoby..." />
        </Collapse>
        <Collapse in={createContact.status !== "pending" && updateContact.status !== "pending"}>
          <Box pt={1}>
            <form noValidate autoComplete="off" onSubmit={(event) => event.preventDefault()}>
              <ClientSelect
                value={clientID || inputData?.klient_id || null}
                onChange={(value) => setClient(value)}
                inputProps={{ autoFocus: !clientID }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="jmeno"
                name="given-name"
                label="Jméno"
                type="text"
                value={jmeno}
                onChange={(event) => setJmeno(event.target.value)}
                inputProps={{ maxLength: 99 }}
                fullWidth
                required
                autoFocus={!!clientID}
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="prijmeni"
                name="family-name"
                label="Příjmení"
                type="text"
                value={prijmeni}
                onChange={(event) => setPrijmeni(event.target.value)}
                inputProps={{ maxLength: 99 }}
                fullWidth
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="email"
                label="E-mail"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                inputProps={{ maxLength: 99 }}
                fullWidth
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="pozice"
                name="organization-title"
                label="Pozice"
                type="text"
                value={pozice}
                onChange={(event) => setPozice(event.target.value)}
                inputProps={{ maxLength: 99 }}
                fullWidth
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="telefon"
                name="phone"
                label="Telefon"
                type="phone"
                value={telefon}
                onChange={(event) => setTelefon(event.target.value)}
                inputProps={{ maxLength: 99 }}
                fullWidth
              />
            </form>
          </Box>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleClose()}>
          Zrušit
        </Button>
        <Button
          onClick={() => (inputData?.id ? handleUpdateContact() : handleCreateContact())}
          disabled={!isValid([client?.id, jmeno, prijmeni, email])}
          autoFocus>
          {inputData?.id ? "Uložit změny" : "Vytvořit kontakt"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  inputData: PropTypes.object,
  user: PropTypes.object.isRequired,
  clientID: PropTypes.number,
  addSnack: PropTypes.func.isRequired,
};

ContactDialog.defaultProps = {
  inputData: null,
};

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) => ({
  addSnack: (message = null, delay = null, action = null) => dispatch({ type: "SNACK_ADD", message, delay, action }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDialog);
