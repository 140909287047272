import { takeEvery, put } from 'redux-saga/effects'

/**
 * Set projects filter.
 * @param {{ data: Object }} param0
 */
function* projectsFilterUpdate({ data }) {
  yield put({ type: 'APP_PROJECTS_FILTER_UPDATE', data })
}

/**
 * Set theme.
 * @param {{ theme: string }} param0
 */
function* updateTheme({ theme }) {
  yield put({ type: 'APP_THEME_UPDATE', theme })
}

/**
 * Root saga function for app segment.
 */
export function* rootApp() {
  // Update projects filter
  yield takeEvery('APP_PROJECTS_FILTER_UPDATE_REQUESTED', projectsFilterUpdate)
  // Update theme
  yield takeEvery('APP_THEME_UPDATE_REQUESTED', updateTheme)
}
