import { useQuery } from "@tanstack/react-query";
import api from "lib/axios";

/**
 * Custom hook for notifications.
 * @param {string|number} uid User ID
 */
export function useNotifications(uid) {
  const { data: notifications, ...rest } = useQuery({
    queryKey: ["notifications", uid],
    queryFn: ({ signal }) => api.get("", { params: { request: "notifications", uid }, signal }),
    select: (res) => (Array.isArray(res.data) ? res.data : []),
    // Automatic re-fetching
    refetchInterval: 30 * 1000,
    enabled: Boolean(uid),
  });
  return { notifications, ...rest };
}
