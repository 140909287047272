import React from "react";
import { Provider } from "react-redux";
import store from "lib/store";
import Theme from "./Theme";
import Router from "./Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { license } from "lib/muix";

LicenseInfo.setLicenseKey(license);

// Query client constructor
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      //staleTime: 5 * 1000,
      //retryOnMount: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Theme>
        <Router />
      </Theme>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
  </QueryClientProvider>
);

export default App;
