import React, { memo, createContext, forwardRef, useMemo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  InputAdornment,
  IconButton,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Fade,
  Tooltip,
  CircularProgress,
  Box,
  Skeleton,
  ListItemIcon,
  Chip,
  Stack,
  useMediaQuery,
  Button,
  Link,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ArticleIcon from "@mui/icons-material/Article";
import CalculateIcon from "@mui/icons-material/Calculate";
import { VariableSizeList } from "react-window";
import useEnhancedState from "lib/hooks/useEnhancedState";
import numberWithSuffix from "lib/numberWithSuffix";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import api from "lib/axios";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { decode } from "lib/special-characters";
import AutoSizer from "react-virtualized-auto-sizer";
import { evaluate } from "mathjs";
import copy from "copy-to-clipboard";

const PADDING_SIZE = 4;

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

/**
 * It renders a list item with a button inside.
 * @returns A list item with a button inside.
 */
const Item = memo(({ data, index, style, isScrolling }) => {
  const item = data?.data?.[index];
  const focusedIndex = data?.focused;

  const itemStyle = {
    ...style,
    top: `${parseFloat(style.top) + PADDING_SIZE}px`,
  };

  // Section
  if (item.type === "section") {
    return (
      <Box key={index} style={itemStyle} sx={{ display: "flex", alignItems: "flex-end" }}>
        <ListSubheader sx={{ pb: 1.5, lineHeight: 1 }}>{item.title}</ListSubheader>
      </Box>
    );
  }

  let primaryText = <Skeleton variant="text" width="40%" />;
  if (!isScrolling && item.primary) {
    const primaryMatches = match(item.primary, data.searchText, { insideWords: true, findAllOccurrences: true });
    const primaryParts = parse(item.primary, primaryMatches);
    primaryText = (
      <>
        {primaryParts.map((part, i) => (
          <Box
            key={i}
            component="span"
            sx={{
              color: part.highlight ? "primary.main" : "text.primary",
            }}>
            {part.text}
          </Box>
        ))}
      </>
    );
  }

  let secondaryText = <Skeleton variant="text" width="30%" />;
  if (!isScrolling && item.secondary) {
    const secondaryMatches = match(item.secondary, data.searchText, { insideWords: true, findAllOccurrences: true });
    const secondaryParts = parse(item.secondary, secondaryMatches);
    secondaryText = (
      <>
        {secondaryParts.map((part, i) => (
          <Box
            key={i}
            component="span"
            sx={{
              color: part.highlight ? "primary.main" : "text.primary",
            }}>
            {part.text}
          </Box>
        ))}
      </>
    );
  }

  if (item.type === "math") {
    primaryText = item.result;
    secondaryText = "Klikni pro zkopírování výsledeku do schránky";
  }

  const projectState = (state) => {
    let chipProps = { label: "Analýza", color: "warning" };
    if (state === 2) chipProps = { label: "Projekt", color: "info" };
    if (state === 3) chipProps = { label: "K uzavření", color: "success" };
    if (state === 4) chipProps = { label: "Uzavřeno", color: "teal" };
    if (state === 5) chipProps = { label: "Vyfakturováno", color: "purple" };
    if (state === 6) chipProps = { label: "Archivováno", color: "darkGrey" };

    return <Chip {...chipProps} size="small" sx={{ lineHeight: 1 }} />;
  };

  return (
    <ListItem key={index} component="div" style={itemStyle} sx={{ p: 0.5, px: 1 }}>
      <ListItemButton
        sx={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "divider",
          borderRadius: 2,
          maxWidth: "100%",
        }}
        onClick={item?.onClick}
        autoFocus={focusedIndex === index}
        onKeyDown={item?.onKeyDown}>
        <ListItemIcon sx={{ justifyContent: "center", pl: 0.5, pr: 2, minWidth: 0 }}>
          {item.type === "user" && <AccountCircleIcon />}
          {item.type === "client" && <SupervisedUserCircleIcon />}
          {item.type === "project" && <ArticleIcon />}
          {item.type === "math" && <CalculateIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="inherit" noWrap sx={{ flexGrow: isScrolling ? 1 : 0 }}>
                {primaryText}
              </Typography>
              {!isScrolling && !!item.data?.deleted && (
                <Chip label="Neaktivní" color="error" size="small" sx={{ lineHeight: 1 }} />
              )}
              {!isScrolling && item.type === "project" && projectState(Number(item.data?.stav))}
            </Stack>
          }
          secondary={secondaryText}
          secondaryTypographyProps={{ noWrap: true }}
        />
      </ListItemButton>
    </ListItem>
  );
});
Item.displayName = "Item";
Item.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  isScrolling: PropTypes.bool,
};
Item.defaultProps = {
  isScrolling: false,
};

/**
 * We create a new component that renders a div with a height equal to the height of the div it renders
 * plus the padding size.
 */
const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height) + PADDING_SIZE * 2}px`,
    }}
    {...rest}
  />
));
innerElementType.displayName = "innerElementType";
innerElementType.propTypes = {
  style: PropTypes.object.isRequired,
};

function QuickSearchDialog({ open, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const inputRef = React.useRef();
  const listRef = React.useRef();
  const [searchText, setSearchText, debouncedSearchText] = useEnhancedState({
    key: "quick-search-text",
    defaultValue: "",
  });
  const [focused, setFocused] = useState(null);

  const handleEnter = () => inputRef?.current?.focus?.();

  const handleExited = () => setFocused(null);

  // Projects
  const projects = useQuery({
    queryKey: ["quick-search-projects"],
    queryFn: ({ signal }) =>
      api.get("is_projekty", {
        params: {
          join: ["is_klient"],
          include: {
            is_projekty: ["id", "number", "jmeno", "klient", "kcislo", "stav", "info", "po", "fa"],
            is_klient: "name",
          },
        },
        signal,
      }),
    select: (res) => res.data?.records || [],
    refetchInterval: 15 * 1000,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: open,
  });

  // Clients
  const clients = useQuery({
    queryKey: ["quick-search-clients"],
    queryFn: ({ signal }) => api.get("is_klient", { params: { include: "id,name,created,deleted" }, signal }),
    select: (res) => res.data?.records || [],
    //refetchInterval: 15 * 1000,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: open,
  });

  // Users
  const users = useQuery({
    queryKey: ["quick-search-users"],
    queryFn: ({ signal }) =>
      api.get("is_uzivatel", { params: { include: "id,jmeno,prijmeni,zkratka,email,deleted" }, signal }),
    select: (res) => res?.records || [],
    //refetchInterval: 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: open,
  });

  const items = useMemo(() => {
    // Get array of search strings
    const searchStrings = debouncedSearchText.split(" ");
    // Combine data
    let combinedData = [];
    let totalCount = 0;
    let count = 0;
    /**
     * It filters the items in the array by checking if the search strings are included in the item.
     * @param item - The item to be filtered.
     * @returns The filtered data.
     */
    const filterFn = (item) => {
      let itemResult = searchStrings.map(() => false);
      const obj = item.type === "project" ? item.data : item;
      obj.__type = `#${item.type}`;
      Object.keys(obj).forEach((field) => {
        let value = String(obj[field]);
        if (item.type === "project") {
          if (["jmeno", "kcislo", "info"].indexOf(field) >= 0) value = decode(value);
          if (field === "number") value = `${value}[^${value.substring(0, 4)}]`;
          if (field === "klient") value = decode(obj[field]?.name);
          if (field === "stav") {
            if (Number(value) === 1) value = "[ANALYSIS][A]";
            if (Number(value) === 2) value = "[PROJECT][P]";
            if (Number(value) === 3) value = "[CLOSING][P]";
            if (Number(value) === 4) value = "[CLOSED][P]";
            if (Number(value) === 5) value = "[INVOICED][P]";
            if (Number(value) === 6) value = "[ARCHIVED][P]";
          }
        }
        // Looking for every search string
        searchStrings.forEach((needle, index) => {
          if (value.toLocaleLowerCase().indexOf(needle.toLocaleLowerCase()) >= 0) {
            itemResult[index] = true;
          }
        });
      });
      // Filter only included results
      itemResult = itemResult.filter((result) => result !== false);
      // True if all search strings are included within item
      return itemResult.length === searchStrings.length;
    };

    const sortAscFn = ({ primary: a }, { primary: b }) => (a > b ? 1 : a < b ? -1 : 0);
    const sortDescFn = ({ primary: a }, { primary: b }) => (a < b ? 1 : a > b ? -1 : 0);

    // Math operations
    let mathResult;
    try {
      // Try evaluate value as math
      if (isNaN(debouncedSearchText)) mathResult = evaluate(String(debouncedSearchText));
    } catch (error) {
      mathResult = false;
    }
    if (mathResult) {
      count++;
      totalCount++;
      combinedData = combinedData.concat([
        {
          type: "math",
          id: "math",
          result: `= ${mathResult}`,
          onClick: (event) => {
            event.preventDefault();
            onClose();
            copy(mathResult);
          },
        },
      ]);
    }

    // Projects
    const preparedProjects = projects.data?.map((project) => ({
      type: "project",
      id: project.id,
      primary: `${project.number || "N/A"} — ${project.jmeno || "N/A"}`,
      secondary: `${project.kcislo || "N/A"}, ${project.klient?.name || "N/A"}`,
      data: project,
      onClick: (event) => {
        event.preventDefault();
        onClose();
        navigate(`/p/${project.id}`);
      },
    }));
    if (Array.isArray(preparedProjects)) {
      totalCount += preparedProjects.length;
      const filteredProjects = preparedProjects.filter(filterFn).sort(sortDescFn);
      if (filteredProjects.length) {
        combinedData = combinedData.concat(
          [{ type: "section", title: `Projekty a analýzy (${filteredProjects.length}/${projects.data.length})` }],
          filteredProjects
        );
        count = count + filteredProjects.length;
      }
    }

    // Users
    const preparedUsers = users.data?.map((user) => ({
      type: "user",
      id: user.id,
      primary: `${user.jmeno} ${user.prijmeni} (${user.zkratka})`,
      secondary: user.email,
      data: user,
      onClick: (event) => {
        event.preventDefault();
        onClose();
        navigate(`/u/${user.id}`);
      },
    }));
    if (Array.isArray(preparedUsers)) {
      totalCount += preparedUsers.length;
      const filteredUsers = preparedUsers.filter(filterFn).sort(sortAscFn);
      if (filteredUsers.length) {
        combinedData = combinedData.concat(
          [{ type: "section", title: `Uživatelé (${filteredUsers.length}/${users.data.length})` }],
          filteredUsers
        );
        count = count + filteredUsers.length;
      }
    }

    // Clients
    const preparedClients = clients.data?.map((client) => ({
      type: "client",
      id: client.id,
      primary: client.name,
      secondary: client.created || "N/A",
      data: client,
      onClick: (event) => {
        event.preventDefault();
        onClose();
        navigate(`/c/${client.id}`);
      },
    }));
    if (Array.isArray(preparedClients)) {
      totalCount += preparedClients.length;
      const filteredClients = preparedClients.filter(filterFn).sort(sortAscFn);
      if (filteredClients.length) {
        combinedData = combinedData.concat(
          [{ type: "section", title: `Klienti (${filteredClients.length}/${clients.data.length})` }],
          filteredClients
        );
        count = count + filteredClients.length;
      }
    }

    // Return result
    return {
      data: combinedData,
      count,
      childCount: combinedData?.length || 0,
      totalCount,
      searchStrings,
      searchText: debouncedSearchText,
      mathResult,
      focused,
    };
  }, [debouncedSearchText, projects.data, users.data, clients.data, onClose, navigate, focused]);

  useEffect(() => {
    if (listRef?.current && items.count) {
      /*
      VariableSizeList caches offsets and measurements for each index for performance purposes. This method clears that cached data for all items after (and including) the specified index. It should be called whenever a item's size changes. (Note that this is not a typical occurrance.)

      By default the list will automatically re-render after the index is reset. If you would like to delay this re-render until e.g. a state update has completed in the parent component, specify a value of false for the second, optional parameter.
      */
      listRef.current?.resetAfterIndex(0, false);
    }
  }, [items]);

  /**
   * * If the user presses the enter key, copy the math result to the clipboard
   * @param event - The event object that was passed to the function.
   */
  const handleInputKeyPress = (event) => {
    const e = event || window.event;
    // If we have some math result, copy that result to clipboard
    if (e.charCode === 13 && !!items?.mathResult) {
      copy(String(items.mathResult));
      onClose();
    }
  };

  /**
   * When the user presses the arrow down key, we want to move the focused item down one.
   * When the user presses the arrow up key, we want to move the focused item up one.
   */
  const handleInputKeyDown = useCallback(
    (event) => {
      const e = event || window.event;
      // Use e.key = "ArrowDown" | "ArrowUp" to navigate through list items
      if (e.key === "ArrowDown") {
        e.preventDefault();
        let newFocused = typeof focused === "number" ? focused + 1 : 0;
        if (newFocused > items.childCount - 1) newFocused = 0;
        if (items?.data?.[newFocused]?.type === "section") newFocused += 1;
        setFocused(newFocused);
        listRef?.current?.scrollToItem?.(newFocused, "smart");
        //listRef?.current?.focus?.();
      }
      if (e.key === "ArrowUp") {
        e.preventDefault();
        let newFocused = typeof focused === "number" ? focused - 1 : items.childCount - 1;
        if (items?.data?.[newFocused]?.type === "section") newFocused -= 1;
        if (newFocused < 0) newFocused = items.childCount - 1;
        setFocused(newFocused);
        listRef?.current?.scrollToItem?.(newFocused, "smart");
        //listRef?.current?.focus?.();
      }
    },
    [focused, setFocused, items]
  );

  /**
   * When the user presses the arrow keys, we want to move the focused item in the list.
   */
  const handleContentKeyDown = (event) => {
    const e = event || window.event;
    // Use e.key = "ArrowDown" | "ArrowUp" to navigate through list items
    if (e.key === "Home") {
      e.preventDefault();
      let newFocused = 0;
      if (items?.data?.[newFocused]?.type === "section") newFocused += 1;
      setFocused(newFocused);
      listRef?.current?.scrollToItem?.(newFocused, "smart");
    }
    if (e.key === "ArrowUp" || e.key === "PageUp") {
      e.preventDefault();
      let newFocused = typeof focused === "number" ? focused - 1 : items.childCount - 1;
      if (items?.data?.[newFocused]?.type === "section") newFocused -= 1;
      if (newFocused < 0) newFocused = items.childCount - 1;
      setFocused(newFocused);
      listRef?.current?.scrollToItem?.(newFocused, "smart");
      //listRef?.current?.focus?.();
    }
    if (e.key === "ArrowDown" || e.key === "PageDown") {
      e.preventDefault();
      let newFocused = typeof focused === "number" ? focused + 1 : 0;
      if (newFocused > items.childCount - 1) newFocused = 0;
      if (items?.data?.[newFocused]?.type === "section") newFocused += 1;
      setFocused(newFocused);
      listRef?.current?.scrollToItem?.(newFocused, "smart");
      //listRef?.current?.focus?.();
    }
    if (e.key === "End") {
      e.preventDefault();
      let newFocused = items.childCount - 1;
      setFocused(newFocused);
      listRef?.current?.scrollToItem?.(newFocused, "smart");
    }
    if (e.key === "Backspace") {
      e.preventDefault();
      setFocused(null);
      inputRef?.current?.focus?.();
    }
  };

  /**
   * The above code is using useMemo to check if the items array is an array and if it is,
   * it will check if it is empty. If it is empty, it will return true.
   */
  const isFetching = useMemo(
    () => !items.data?.length || users.isFetching || clients.isFetching || projects.isFetching,
    [items.data, users.isFetching, clients.isFetching, projects.isFetching]
  );

  /**
   * `isLoading` is a memoized function that returns `true` if any of the `isLoading` properties
   * of the `users`, `clients`, or `projects` objects are `true`.
   */
  const isLoading = useMemo(
    () => users.isLoading || clients.isLoading || projects.isLoading,
    [users.isLoading, clients.isLoading, projects.isLoading]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onTransitionEnter={handleEnter}
      onTransitionExited={handleExited}>
      <DialogTitle sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, bgcolor: "background.paper" }}>
        <InputBase
          inputRef={inputRef}
          fullWidth
          placeholder="Hledat..."
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
            // Reset selection
            setFocused(null);
          }}
          onKeyPress={handleInputKeyPress}
          onKeyDown={handleInputKeyDown}
          onFocus={(event) => event.target.select()}
          inputProps={{ "aria-label": "search data" }}
          startAdornment={
            <InputAdornment position="start">
              <SearchRoundedIcon color="primary" />
            </InputAdornment>
          }
          autoFocus
          endAdornment={
            <InputAdornment position="end">
              <Fade in={Boolean(searchText.length)}>
                <Tooltip title="Vymazat">
                  <IconButton size="small" onClick={() => setSearchText("")}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              </Fade>
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent
        sx={{ p: 0, bgcolor: "background.paper", minHeight: 500, overflow: "hidden" }}
        onKeyDown={handleContentKeyDown}>
        <AutoSizer>
          {({ width, height }) => {
            if (items.totalCount === 0 || isLoading) {
              return (
                <Box
                  sx={{
                    height,
                    width,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <CircularProgress size={24} />
                  <Typography sx={{ pl: 1 }}>Načítání dat...</Typography>
                </Box>
              );
            }
            if (items.count === 0 && !isLoading) {
              return (
                <Box
                  sx={{
                    height,
                    width,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Typography>Nebyly nalezeny žádné záznamy.</Typography>
                  <Typography variant="body2" sx={{ textAlign: "center", pt: 4, px: fullScreen ? 4 : 13 }}>
                    {"Pokud hledáš nějaký projekt, zkus štěstí přímo v"}&nbsp;
                    <Link component={RouterLink} to="/p" onClick={() => onClose()}>
                      projektech
                    </Link>
                    {", kde jsou širší možnosti hledání."}
                  </Typography>
                </Box>
              );
            }
            if (items.count > 0 && !isLoading) {
              return (
                <VariableSizeList
                  ref={listRef}
                  height={height}
                  width={width}
                  estimatedItemSize={82}
                  itemSize={(index) => (items?.data?.[index]?.type === "section" ? 48 : 82)}
                  itemCount={items?.childCount || 0}
                  itemData={items}
                  overscanCount={5}
                  innerElementType={innerElementType}
                  onKeyDown={handleInputKeyDown}
                  //useIsScrolling
                >
                  {Item}
                </VariableSizeList>
              );
            }
          }}
        </AutoSizer>
      </DialogContent>
      <DialogActions sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}`, bgcolor: "background.paper" }}>
        <Fade in={isFetching && !isLoading}>
          <CircularProgress size={16} />
        </Fade>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body2" color="text.secondary">
          {debouncedSearchText.length > 0 ? "Nalezeno: " : "Celkem: "}
          {items.count !== items.totalCount && `${items.count}/`}
          {numberWithSuffix(items.totalCount, "záznam", "záznamy", "záznamů")}
        </Typography>
        {fullScreen && (
          <Button onClick={onClose} sx={{ ml: 1 }}>
            Zavřít
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

QuickSearchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

QuickSearchDialog.defaultProps = {
  open: false,
};

export default QuickSearchDialog;
