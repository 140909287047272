import axios from "axios";

/**
 * Axios instance preconfigured for all API calls.
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_API2_URL,
  withCredentials: true,
  /*transformRequest: [
    (data) => ({
      csrf: sessionStorage.getItem("csrf"),
      ...data,
    }),
    ...axios.defaults.transformRequest,
  ],*/
  paramsSerializer: (params) => paramSerializer(params),
});

api.interceptors.request.use((config) => {
  // use config.params if it has been set
  config.params = config.params || {};

  // Login post request
  if (config.method === "post" && config.url === "" && !!config.data?.username && !!config.data?.password) {
    config.baseURL = process.env.REACT_APP_API_URL;
  }
  // any other request
  else {
    // always include token within the params
    config.params["csrf"] = sessionStorage.getItem("csrf");
    // Base get requests
    if (config?.url?.length > 0) {
      config.baseURL = config.baseURL + "/records/";
    }
    // Custom requests
    else if (config.params?.["request"]) {
      config.baseURL = process.env.REACT_APP_CUSTOM_API_URL;
      //config.params["request"] = config.url;
      config.url = "";
    }
  }
  // return config
  return config;
});

export default api;

/**
 * Parse given object into URL parameters
 *
 * @param {object} params
 * @param {array} keys
 * @param {boolean} isArray
 * @param {number} level
 */
export function paramSerializer(params = {}, keys = [], isArray = false, level = 0) {
  const p = Object.keys(params)
    .map((key) => {
      let val = params[key];

      // Include object
      if (key === "include") {
        return `include=${encodeURIComponent(parseIncludeParam(val))}`;
      }

      if ("[object Object]" === Object.prototype.toString.call(val) || Array.isArray(val)) {
        if (level > 0 && isArray) {
          return `${keys[0]}[]=${encodeURIComponent(val.join(","))}`;
        } else {
          keys.push(Array.isArray(params) ? "" : key);
          return paramSerializer(val, keys, Array.isArray(val), level + 1);
        }
      } else {
        let tKey = key;
        if (keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key];
          tKey = tKeys.reduce((str, k) => (str === "" ? k : `${str}[${k}]`), "");
        }
        if (isArray) {
          return `${encodeURIComponent(tKey)}[]=${encodeURIComponent(val)}`;
        } else {
          return `${encodeURIComponent(tKey)}=${encodeURIComponent(val)}`;
        }
      }
    })
    .join("&");

  keys.pop();
  return p;
}

/**
 * Serialize include object to comma separated string.
 * @param {object|array|string} format Format to serilize
 * @param {string} parent Parent string
 */
export function parseIncludeParam(format, parent) {
  let parsed = [];

  if (typeof format === "string") {
    parsed.push(parent ? `${parent}.${format}` : format);
  } else if ("[object Object]" === Object.prototype.toString.call(format)) {
    Object.keys(format).forEach((p) => {
      parsed.push(parseIncludeParam(format[p], parent ? `${parent}.${p}` : p));
    });
  } else if (Array.isArray(format)) {
    format.forEach((f) => {
      parsed.push(parseIncludeParam(f, parent));
    });
  }
  //console.log(parsed.join(','))
  return parsed.join(",");
}
