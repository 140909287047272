import React from 'react'

/**
 * Encode string.
 *
 * @param {string} str Decoded string.
 */
export const encode = (str) =>
  typeof str === 'string'
    ? str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
    : ''

/**
 * Decode string.
 *
 * @param {string} str Encoded string.
 */
export const decode = (str) =>
  typeof str === 'string'
    ? str
        .replace(/&amp;/g, '&')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    : ''

/**
 * Convert multiline text to HTML format.
 *
 * @param {string} text Text to format.
 */
export const multilineText = (text) => {
  if (typeof text !== 'string') return ''
  const arr = decode(text).split(/\r?\n/)
  return arr.map((t, i) => (
    <span key={i}>
      {t}
      {i < arr.length - 1 && <br />}
    </span>
  ))
}
