/**
 * Decode HTML string and return plain text.
 *
 * @param {string} html HTML string to decode.
 * @returns {string}
 */
export const decodeHtml = html => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
