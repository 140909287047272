import React, { useReducer, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@tanstack/react-query";
import { api } from "lib/axios";
import ListboxComponent from "./ListboxComponent";
import renderGroup from "./renderGroup";

const stateReducer = (state, payload = {}) => ({ ...state, ...payload });

function UserSelect({
  value,
  defaultValue,
  onChange,
  inputProps,
  canBeNull,
  includeExternal,
  isExternal,
  ...otherProps
}) {
  const user = useSelector(({ user }) => user, shallowEqual);

  const [state, dispatch] = useReducer(stateReducer, { selected: null, isExternal: false, defaultName: null });

  const enhancedUser = (u) => {
    const _isDisabled = Boolean(u.deleted);
    return {
      ...u,
      _display: `${u.jmeno} ${u.prijmeni}`,
      _groupName: _isDisabled ? "Nektivní" : "Zaměstnanci",
      _groupOrder: _isDisabled ? 3 : 1,
      _searchString: String(`${u.jmeno} ${u.prijmeni} ${u.email} ${u.zkratka}`).toLocaleLowerCase(),
      _isDisabled,
      _isExternal: false,
    };
  };

  const enhancedExternal = (comp) => {
    const _isDisabled = Boolean(comp.deleted || !comp.nda);
    const extUsers =
      comp?.ext_users
        ?.map((u) => `${comp.first_name || ""} ${u.middle_name || ""} ${u.last_name || ""} ${u.email}`)
        ?.join(" ") || "";
    return {
      ...comp,
      _display: comp.name,
      _groupName: _isDisabled ? "Neaktivní" : "Externí dodavatelé",
      _groupOrder: _isDisabled ? 3 : 2,
      _searchString: String(`${comp.name} ${extUsers}`).toLocaleLowerCase(),
      _isDisabled,
      _isExternal: true,
    };
  };

  const users = useQuery({
    queryKey: ["select-user"],
    queryFn: ({ signal }) =>
      api.get("is_uzivatel", {
        params: {
          order: ["deleted,asc", "jmeno,asc", "prijmeni,desc"],
          include: "id,jmeno,prijmeni,email,zkratka,deleted",
        },
        signal,
      }),
    select: (res) => res.data?.records || [],
    refetchOnWindowFocus: false,
    // keepPreviousData
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (users.data?.length > 0) {
      let updatedState = {};
      // Should we fill selected user
      if (!state.isExternal && !state.selected && value) {
        const found = users.data.find((u) => Number(u.id) === Number(value));
        if (found) updatedState.selected = enhancedUser(found);
      }
      // Select current user
      if (!canBeNull && !state.isExternal && !state.selected && !value) {
        const found = users.data.find((u) => Number(u.id) === Number(user?.id));
        if (found) {
          updatedState.selected = enhancedUser(found);
          onChange(updatedState.selected);
        }
      }
      // Should we fill default user
      if (defaultValue && !state.defaultValue) {
        const found = users.data.find((u) => Number(u.id) === Number(defaultValue));
        if (found) {
          const enhancedFound = enhancedUser(found);
          updatedState.defaultValue = enhancedFound?._display;
        }
      }
      dispatch(updatedState);
    }
  }, [
    users.data,
    users.status,
    users.isSuccess,
    value,
    user?.id,
    state.isExternal,
    state.selected,
    state.defaultValue,
    canBeNull,
    defaultValue,
    onChange,
  ]);

  const external = useQuery({
    queryKey: ["select-external"],
    queryFn: ({ signal }) =>
      api.get("ext_company", {
        params: {
          order: "name,asc",
          join: "ext_users",
          include: {
            ext_company: ["id", "name", "nda", "deleted"],
            ext_users: ["id", "first_name", "middle_name", "last_name", "email", "deleted"],
          },
        },
        signal,
      }),
    select: (res) => res.data?.records || [],
    refetchOnWindowFocus: false,
    // keepPreviousData
    placeholderData: (previousData) => previousData,
  });

  useEffect(() => {
    if (state.isExternal && !state.selected && value && external.data?.length > 0) {
      const found = external.data.find((u) => Number(u.id) === Number(value));
      if (found) {
        const selected = enhancedExternal(found);
        dispatch({ selected });
      }
    }
  }, [external.data, state.isExternal, state.selected, value, external.status]);

  useEffect(() => {
    let selected = null;
    let defaultUser = null;
    // Selected internal user
    if (!isExternal && value && users.data?.length > 0) {
      const found = users.data.find((u) => Number(u.id) === Number(value));
      if (found) selected = enhancedUser(found);
    }
    // Selected external company
    else if (isExternal && value && external.data?.length > 0) {
      const found = external.data.find((u) => Number(u.id) === Number(value));
      if (found) selected = enhancedExternal(found);
    }
    // Get default value
    if (defaultValue && users.data?.length > 0) {
      const found = users.data.find((u) => Number(u.id) === Number(value));
      if (found) defaultUser = enhancedUser(found);
    }
    // Update internal state
    dispatch({ selected, isExternal, defaultValue: defaultUser?._display });
  }, [value, defaultValue, isExternal, users.data, external.data]);

  const data = useMemo(() => {
    let memorized = [];
    // Internal users
    if (users.data?.length > 0) {
      users.data.forEach((u) => {
        memorized.push(enhancedUser(u));
      });
    }
    // External companies
    if (includeExternal && external.data?.length > 0) {
      external.data.forEach((comp) => {
        memorized.push(enhancedExternal(comp));
      });
    }
    // Return sorted data
    return memorized.sort((a, b) => {
      const aa = `${a._groupOrder} ${a._display}`;
      const bb = `${b._groupOrder} ${b._display}`;
      return aa < bb ? -1 : aa > bb ? 1 : 0;
    });
  }, [users.data, external.data, includeExternal]);

  /**
   * Handle autocomplete change.
   * @param {Event} event Provided Event
   * @param {object} selectedObject Selected user or external company object
   */
  const handleChange = (event, selectedObject) => {
    event.preventDefault();
    let selected = null;
    // Get values
    if (!canBeNull && !selectedObject) {
      selected = enhancedUser(user);
    } else {
      selected = selectedObject ? { ...selectedObject } : null;
    }
    // Update local state
    dispatch({ selected, isExternal: selected?._isExternal || false });
    // Notify parent about changes
    onChange(selected);
  };

  return (
    <Autocomplete
      //id="virtualized-user-select"
      fullWidth
      disableListWrap
      //autoSelect
      //classes={{ listbox: classes.listbox }}
      sx={{
        "& .MuiAutocomplete-listbox": {
          boxSizing: "border-box",
          "& ul": {
            padding: 0,
            margin: 0,
          },
        },
      }}
      ListboxComponent={ListboxComponent}
      value={state.selected}
      onChange={handleChange}
      options={data || []}
      groupBy={(option) => option._groupName}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Uživatel"
          placeholder={state?.defaultValue || "Začni psát a/nebo vyber..."}
          {...inputProps}
        />
      )}
      renderOption={(props, option) => (
        <Typography noWrap {...props}>
          {option._display}
        </Typography>
      )}
      isOptionEqualToValue={(option, selected) =>
        option?.id &&
        selected?.id &&
        Number(option.id) === Number(selected.id) &&
        option?._isExternal === state?.isExternal
      }
      getOptionLabel={(option) => option?._display}
      getOptionDisabled={(option) => option._isDisabled}
      filterOptions={(options, { inputValue }) => {
        const str = String(inputValue).toLocaleLowerCase();
        return options.filter((option) => String(option._searchString).indexOf(str) >= 0);
      }}
      loading={users.isFetching || external.isFetching}
      loadingText="Načítání..."
      noOptionsText="Tomuto filtru nikdo nevyhovuje."
      clearText="Vymazat"
      openText="Otevřít"
      closeText="Zavřít"
      disableClearable
      {...otherProps}
    />
  );
}

UserSelect.propTypes = {
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  canBeNull: PropTypes.bool,
  includeExternal: PropTypes.bool,
  isExternal: PropTypes.bool,
};

UserSelect.defaultProps = {
  value: null,
  onChange: () => {},
  inputProps: {},
  canBeNull: false,
  includeExternal: false,
  isExternal: false,
};

export default React.memo(UserSelect);
