import icon from 'images/gdtp_icon_inversed.png'

/**
 * Desktop notification.
 *
 * More info at `https://developer.mozilla.org/en-US/docs/Web/API/notification`
 *
 * @param {string} message Message to display.
 */
export default function desktopNotification(message, ask = false) {
  const title = 'Global DTP - IS2'
  if (message) {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      //alert('Tento prohlížeč nepodporuje nativní upozornění!')
      console.log('Tento prohlížeč nepodporuje nativní upozornění!')
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(title, { icon, body: message })
    }

    // If not denier or granted, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(title, { icon, body: message })
        }
      })
    }

    // Force ask if denied
    else if (Notification.permission === 'denied' && ask) {
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(title, { icon, body: message })
        }
      })
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
  }
}
