import { useState, useEffect } from "react";

/**
 * Debounce search term so that it only gives us latest value
 * if searchTerm has not been updated within within the given delay.
 * The goal is to only have the API call fire when user stops typing
 * so that we aren't hitting our API rapidly.
 *
 * https://usehooks.com/useDebounce/
 *
 * Example:
 * ```
 *  const debouncedSearchTerm = useDebounce(searchTerm, 500);
 *  useEffect(
 *    () => {
 *      if (debouncedSearchTerm) ... // call api
 *    },
 *    [debouncedSearchTerm] // Only call effect if debounced search term changes
 *  );
 * ```
 *
 * @param {any} value
 * @param {number} delay
 */
export function useDebounce(value: any, delay: number): any {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export default useDebounce;
