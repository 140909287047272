import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
//import SnackbarContent from '@mui/material/SnackbarContent'
import Button from "@mui/material/Button";

class Snacks extends React.Component {
  state = {
    open: false,
    snack: {},
  };

  /**
   * Triggered when conponent is mounted.
   *
   */
  componentDidMount() {
    this.processQueue();
  }

  /**
   * Triggered when conponent is updated.
   *
   * @param {boject} prevProps Provided by React
   */
  componentDidUpdate(prevProps) {
    const { snacks } = this.props;
    if (prevProps.snacks !== snacks) {
      const { open, snack } = this.state;
      // Verify that current snack doesn't have action
      if (!snack.action || !open) {
        // Immediately begin dismissing current message
        // to start showing new one
        if (open && snacks.length > 0 && snacks[0]["key"] !== snack.key) {
          this.setState({ open: false });
        }
        // Otherwise just show snack
        else this.processQueue();
      }
    }
  }

  /**
   * Show the first snack in queue.
   *
   */
  processQueue() {
    const { snacks, removeSnack } = this.props;
    if (snacks.length > 0) {
      this.setState({
        snack: { ...snacks[0] },
        open: true,
      });
      removeSnack();
    }
  }

  /**
   * Callback fired when the component requests to be closed.
   * Typically onClose is used to set state in the parent component,
   * which is used to control the Snackbar open prop.
   * The reason parameter can optionally be used to control
   * the response to onClose, for example ignoring clickaway.
   *
   * @param {object} event The event source of the callback
   * @param {string} reason Can be:"timeout" (autoHideDuration expired) or: "clickaway"
   * @returns {void}
   */
  handleClose = (event, reason) => {
    // Click away will not close snackbar
    if (reason === "clickaway") return;
    // Close snackbar
    this.setState({ open: false });
  };

  /**
   * Callback fired when the transition has exited.
   *
   */
  handleExited = () => {
    this.processQueue();
  };

  render() {
    const { open, snack } = this.state;
    const { key, message, delay, action } = snack;

    return (
      <Snackbar
        key={key}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={open}
        autoHideDuration={delay}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{message}</span>}
        {...(action
          ? {
              action: (
                <Button
                  color="primary"
                  onClick={() => {
                    action.fn();
                    this.handleClose();
                  }}>
                  {action.text}
                </Button>
              ),
            }
          : {})}
        TransitionProps={{
          onExited: this.handleExited,
        }}
      />
    );
  }
}

Snacks.propTypes = {
  snacks: PropTypes.array.isRequired,
  removeSnack: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({ snacks: state.snacks });

const mapDispatchToProps = (dispatch) => ({
  removeSnack: () => dispatch({ type: "SNACK_REMOVE" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snacks);
