import api from "lib/axios";

/**
 * Add subactivities under the parent ones.
 * @param {array} activities
 * @param {number} lvl_id
 */
function makeHiearchy(activities, lvl_id = null) {
  const currentLevel = activities.filter((a) => a.lvl_ref === lvl_id);
  currentLevel.forEach((a) => (a.subs = makeHiearchy(activities, a.lvl_id)));
  return currentLevel;
}

/**
 * Make visible only the latest activities in hiearchy.
 * @param {array} activities
 * @param {array} parentNames
 */
function parseHiearchy(activities, parentNames = []) {
  let parsed = [];
  activities.forEach((a) => {
    if (a.subs.length > 0) {
      const subs = parseHiearchy(a.subs, [...parentNames, a.nazev]);
      subs.forEach((s) => parsed.push(s));
    } else {
      a.path = parentNames;
      parsed.push(a);
    }
  });
  return parsed;
}

export default async function (uid) {
  // Get activities
  const rawActivities = await api
    .get("is_aktivity", {
      params: {
        filter: [
          ["uzivatel", "eq", uid],
          ["stop", "nis"],
          ["deleted", "is"],
        ],
        page: "1,5",
        order: "start,desc",
      },
    })
    .then((res) => res.data?.records || []);

  const filtered = [];

  // Filter out activities with the same "typ" and "reference"
  rawActivities.forEach((activity) => {
    // Decide if the same activity is already included
    const duplicateActivity = filtered.find((a) => a.typ === activity.typ && a.reference === activity.reference);
    // Add only if not already included
    if (!duplicateActivity) filtered.push(activity);
  });

  const activitiesPromises = filtered.map(async (activity) => {
    // Fetch reference
    const rawReference = await api
      .get(Number(activity.typ) === 1 ? "is_zakladni_aktivity" : "is_projektove_aktivity", {
        params: { filter: [["id", "eq", activity.reference]] },
      })
      .then((res) => res.data?.records || []);
    // If we have some activity, we need to fetch also reference
    let ref = rawReference?.[0];
    if (ref) {
      // In case it's project activity,
      // we have to get full path
      if (Number(activity.typ) === 2) {
        // Fetch other project activities
        const rawPA = await api
          .get("is_projektove_aktivity", {
            params: {
              filter: [
                ["proj_ref", "eq", ref.proj_ref],
                ["deleted", "is"],
              ],
            },
          })
          .then((res) => res.data?.records || []);
        // Sort raw project activities by lvl_id
        const sortedRawPA = rawPA.sort((a, b) => (a.lvl_id < b.lvl_id ? -1 : a.lvl_id > b.lvl_id ? 1 : 0));
        // Create hiearchy
        const paHiearchy = makeHiearchy(sortedRawPA);
        // Parse hiearchu
        const paParsed = parseHiearchy(paHiearchy);
        // Find actual reference object
        ref = paParsed.find((a) => Number(a.id) === Number(activity.reference));
        if (ref?.proj_ref) {
          // Get referenced project
          const rawPrj = await api
            .get(`is_projekty/${ref.proj_ref}`, {
              params: { columns: ["number", "jmeno"] },
            })
            .then((res) => res.data);
          if (rawPrj?.number && rawPrj?.jmeno) {
            ref.path = [`${rawPrj.number} — ${rawPrj.jmeno}`].concat(ref.path);
          }
        }
      }
    }
    return { ...activity, ref };
  });

  const activities = await Promise.all(activitiesPromises);

  return activities;
}
