import {generateLicense} from "@mui/x-license-pro";

const getExpiryDate = (date = new Date(), years = 1000) => {
  date.setFullYear(date.getFullYear() + years);
  return date;
};

export const license = generateLicense({
  orderNumber: "1234567890",
  expiryDate: getExpiryDate(),
  scope: "premium",
  licensingModel: "subscription",
});

// premium subscription na 1000 let
// 7eaf140c9aa548845cd45e40d11b5cbcTz0xMjM0NTY3ODkwLEU9MzMyMjYzOTEyNzg2NjUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y
