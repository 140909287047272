import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ThemeProvider, StyledEngineProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { blue, red, grey, purple, teal } from "@mui/material/colors";
import { csCZ } from "@mui/material/locale";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

// Most of react-virtualized's styles are functional (eg position, size).
// Functional styles are applied directly to DOM elements.
// The Table component ships with a few presentational styles as well.
// They are optional, but if you want them you will need to also import the CSS file.
// This only needs to be done once; probably during your application's bootstrapping process.
import "react-virtualized/styles.css";

const Theme = ({ children, theme }) => {
  const [clientTheme, setClientTheme] = useState("dark");

  // https://medium.com/@jonas_duri/enable-dark-mode-with-css-variables-and-javascript-today-66cedd3d7845
  // https://webkit.org/blog/8840/dark-mode-support-in-webkit/
  useEffect(() => {
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches;
    const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
    const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

    window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => e.matches && setClientTheme("dark"));
    window.matchMedia("(prefers-color-scheme: light)").addListener((e) => e.matches && setClientTheme("light"));

    if (isDarkMode) setClientTheme("dark");
    if (isLightMode) setClientTheme("light");
    if (isNotSpecified || hasNoSupport) {
      console.log(
        "You specified no preference for a color scheme or your browser does not support it. I Schedule dark mode during night time."
      );
      const now = new Date();
      const hour = now.getHours();
      if (hour < 4 || hour >= 16) {
        setClientTheme("dark");
      } else {
        setClientTheme("light");
      }
    }
  }, []);

  let baseTheme = useMemo(() => createTheme(), []);

  const themeConfig = useMemo(
    () =>
      responsiveFontSizes(
        createTheme({
          palette: {
            mode: theme || clientTheme,
            primary: blue,
            secondary: red,
            purple: baseTheme.palette.augmentColor({
              color: {
                main: purple[500],
                light: purple[200],
              },
              name: "purple",
            }),
            teal: baseTheme.palette.augmentColor({
              color: {
                main: teal[500],
              },
              name: "teal",
            }),
            darkGrey: baseTheme.palette.augmentColor({
              color: {
                main: String(theme || clientTheme) === "light" ? grey[500] : grey[900],
                contrastText: String(theme || clientTheme) === "light" ? "#fff" : grey[500],
              },
              name: "darkGrey",
            }),
            darkGray: baseTheme.palette.augmentColor({
              color: {
                main: String(theme || clientTheme) === "light" ? grey[500] : grey[900],
                contrastText: String(theme || clientTheme) === "light" ? "#fff" : grey[500],
              },
              name: "darkGray",
            }),
            meGrey: baseTheme.palette.augmentColor({
              color: {
                main: String(theme || clientTheme) === "light" ? grey[700] : grey[300],
                contrastText: String(theme || clientTheme) === "light" ? "#fff" : grey[900],
              },
              name: "meGrey",
            }),
            background: {
              appBar: String(theme || clientTheme) === "light" ? "#fff" : grey[800],
              default: String(theme || clientTheme) === "light" ? "#fafafa" : "#303030",
              paper: String(theme || clientTheme) === "light" ? "#fff" : grey[800],
            },
          },
          // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
          components: {
            MuiCssBaseline: {
              styleOverrides: `
              :root {
                color-scheme: ${theme || clientTheme}
              }
            `,
            },
          },
          csCZ,
        })
      ),
    [baseTheme, theme, clientTheme]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <LocalizationProvider adapterLocale="cs-cz" dateAdapter={AdapterMoment}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
};

Theme.defaultProps = {
  theme: undefined,
};

const mapStateToProps = (state) => ({
  theme: state?.app?.theme || undefined,
});

export default connect(mapStateToProps)(Theme);
