const initialState = {
  theme: undefined,
}

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    // Update projects filter
    case 'APP_PROJECTS_FILTER_UPDATE': {
      return { ...state, ...{ projectsFilter: action.data } }
    }
    case 'APP_THEME_UPDATE': {
      return { ...state, ...{ theme: action.theme } }
    }

    default:
      return state
  }
}
