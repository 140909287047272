import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import routes from "lib/routes";
import Tooltip from "@mui/material/Tooltip";
import ViewLevelDialog from "./ViewLevel.dialog";
import UserAvatar from "components/UserAvatar";
import IconLockOutlined from "@mui/icons-material/LockOutlined";
import IconMenu from "@mui/icons-material/MenuOutlined";
import IconFace from "@mui/icons-material/FaceOutlined";
import IconLocalState from "@mui/icons-material/CodeOutlined";
import IconSearch from "@mui/icons-material/SearchOutlined";
import GlobalDTPlight from "images/gdtp_logo_light.svg";
import GlobalDTPdark from "images/gdtp_logo_dark.svg";
import LocalStateDialog from "./LocalState.dialog";
import QuickSearchNEWDialog from "./QuickSearchNEW.dialog";
import Notifications from "./Notifications";
import Snackbar from "@mui/material/Snackbar";
import CustomSnackbarContent from "components/CustomSnackbarContent";
import AddMenu from "./AddMenu";
import RunningActivityWidget from "./RunningActivityWidget";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "lib/hooks/useAuth";
import { Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Box } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import isAppOnline from "lib/isAppOnline";
import { alpha } from "@mui/material/styles";

const DRAWER_WIDTH = 240;

function Layout() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogged, user } = useAuth();
  const validToken = useSelector(({ db }) => db?.valid_token, shallowEqual);
  const [isOnline, setIsOnline] = useState(true);
  const dispatch = useDispatch();
  const userLogout = () => dispatch({ type: "USER_LOGOUT" });
  const setTheme = (theme) => dispatch({ type: "APP_THEME_UPDATE_REQUESTED", theme });
  //const setInvalidToken = () => dispatch({ type: "DB_INVALIDATE_TOKEN" });
  const [open, setOpen] = useState(false);
  const [changeViewLevel, setChangeViewLevel] = useState(false);
  const [localStateDialog, setLocalStateDialog] = useState(false);
  const [showQuickSearchNEW, setShowQuickSearchNEW] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  // Mount/unmounted
  useEffect(() => {
    const onlineCheck = setInterval(async () => {
      // Invalidation status
      const invalidateToken = sessionStorage.getItem("invalidateToken");
      const isInvalidating = invalidateToken === "REQUESTED" || invalidateToken === "PROCESSING";
      // Connection status
      const isOnline = await isAppOnline();
      // Set result
      setIsOnline(isOnline && !isInvalidating);
    }, 10 * 1000);

    function handleWindowKeyPress(event) {
      const e = event || window.event;
      const isMac = navigator.platform.indexOf("Mac") > -1;
      const isModKey = isMac ? e.metaKey : e.ctrlKey;
      // Ctrl/Cmd + k
      if (isModKey && e.key === "k" && e.defaultPrevented === false) {
        e.preventDefault();
        setShowQuickSearchNEW(true);
      }
    }
    // Add event listener on load
    window.addEventListener("keydown", handleWindowKeyPress);
    // TODO: Implement check version here, don't forget to unsubscribe when unmounted.

    return () => {
      // Remove event listener and clear interval when unmounted
      window.removeEventListener("keydown", handleWindowKeyPress);
      clearInterval(onlineCheck);
    };
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If pathname changes, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  /**
   * Toggle drawer open or closed.
   * @param {boolean} requiredState Open or close drawer.
   * @memberof Layout
   */
  const handleDrawerToggle = (requiredState) => {
    setOpen((open) => (requiredState ? requiredState : !open));
  };

  // Drawer content
  const drawer = (
    <>
      <Box sx={{ height: "64px", flexShrink: 0 }} />
      <List component="nav" sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
        {routes
          .filter((route) => !route.hideInDrawer)
          .filter((route) => route?.minUserLevel === undefined || route.minUserLevel <= user.userLevel)
          .filter((route) => route?.onlyForUsers === undefined || route.onlyForUsers.indexOf(user.id) >= 0)
          .map((route, index, arr) =>
            route.divider === true ? (
              index < arr.length - 1 ? (
                <Divider key={index} style={{ marginTop: 8, marginBottom: 8 }} />
              ) : null
            ) : (
              <ListItem
                key={index}
                button
                onClick={() => {
                  if (mobile) setOpen(false);
                  navigate(route.path);
                }}
                disabled={Boolean(route.disabled)}>
                <ListItemIcon
                  sx={{
                    ...(location.pathname === route.path && {
                      color: theme.palette.primary.main,
                    }),
                  }}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      noWrap
                      variant="subtitle1"
                      sx={{
                        ...(location.pathname === route.path && {
                          color: theme.palette.primary.main,
                        }),
                      }}>
                      {route.label}
                    </Typography>
                  }
                />
              </ListItem>
            )
          )}
      </List>
    </>
  );

  if (!isLogged) return <Outlet />;

  const handleOpenUserMenu = (event) => setUserMenuAnchor(event.currentTarget);

  const handleCloseUserMenu = () => setUserMenuAnchor(null);

  return (
    <>
      <AppBar
        square
        elevation={1}
        position="fixed"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: theme.palette.background.appBar,
          color: theme.palette.mode === "dark" ? alpha("#fff", 0.5) : alpha("#000", 0.5),
        }}
        color="default">
        <Toolbar
          disableGutters
          sx={{
            ...theme.mixins.toolbar,
            height: "63px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            boxSizing: "border-box",
            padding: "0 8px",
          }}>
          <IconButton
            color="inherit"
            aria-label="Menu"
            onClick={() => handleDrawerToggle()}
            sx={{
              marginRight: theme.spacing(2),
              [theme.breakpoints.down("lg")]: {
                marginRight: theme.spacing(1),
              },
            }}
            size="large">
            <IconMenu />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "center",
              overflow: "hidden",
            }}>
            {!mobile && (
              <img
                src={theme.palette.mode === "dark" ? GlobalDTPlight : GlobalDTPdark}
                alt="Global DTP"
                style={{
                  height: 36,
                  marginRight: `calc(${theme.spacing(2)} + 6px)`,
                }}
              />
            )}
          </Box>
          {!mobile && <RunningActivityWidget />}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: theme.spacing(1),
              paddingRight: theme.spacing(1),
              [theme.breakpoints.down("lg")]: {
                paddingRight: 0,
              },
            }}>
            {/*<Button onClick={() => setInvalidToken()}>TEST</Button>*/}
            <AddMenu />

            <Tooltip
              title={
                <>
                  Rychlé hledání
                  <Box
                    component="span"
                    sx={{
                      color: (theme) => theme.palette.secondary.contrastText,
                      //border: (theme) => `1px solid ${theme.palette.common.white}`,
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      backgroundColor: (theme) => theme.palette.secondary.main,
                      padding: "2px 4px",
                      ml: 1,
                    }}>
                    {`${navigator.platform.indexOf("Mac") > -1 ? "⌘" : "Ctrl"}+k`}
                  </Box>
                </>
              }>
              <IconButton onClick={() => setShowQuickSearchNEW(true)} size="large">
                <IconSearch />
              </IconButton>
            </Tooltip>
            <QuickSearchNEWDialog open={showQuickSearchNEW} onClose={() => setShowQuickSearchNEW(false)} />

            <Notifications />

            <Tooltip title={`${user.jmeno} ${user.prijmeni}`}>
              <IconButton
                onClick={handleOpenUserMenu}
                aria-controls={userMenuAnchor ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={userMenuAnchor ? "true" : undefined}>
                <UserAvatar text={user.zkratka} light={false} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={userMenuAnchor}
              id="user-menu"
              open={Boolean(userMenuAnchor)}
              onClose={handleCloseUserMenu}
              onClick={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(`/u/${user.id}?tab=info`);
                }}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Profil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate(`/u/${user.id}?tab=options`);
                }}>
                <ListItemIcon>
                  <TuneIcon />
                </ListItemIcon>
                Moje nastavení
              </MenuItem>
              {!user?.__forceDarkMode && (
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    setTheme(theme.palette.mode === "dark" ? "light" : "dark");
                  }}>
                  <ListItemIcon>{theme.palette.mode === "dark" ? <LightModeIcon /> : <ModeNightIcon />}</ListItemIcon>
                  {theme.palette.mode === "dark" ? "Denní režim" : "Noční režim"}
                </MenuItem>
              )}
              {user.id === 1 && <Divider />}
              {user.id === 1 && (
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    setChangeViewLevel(true);
                  }}>
                  <ListItemIcon>
                    <IconFace />
                  </ListItemIcon>
                  Změna oprávnění
                </MenuItem>
              )}
              {user.id === 1 && (
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    setLocalStateDialog(true);
                  }}>
                  <ListItemIcon>
                    <IconLocalState />
                  </ListItemIcon>
                  Zobrazit local state
                </MenuItem>
              )}
              <Divider />
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  userLogout();
                }}>
                <ListItemIcon>
                  <IconLockOutlined fontSize="small" />
                </ListItemIcon>
                Odhlásit se
              </MenuItem>
            </Menu>
            {user?.id === 1 && (
              <>
                <LocalStateDialog open={localStateDialog} onClose={() => setLocalStateDialog(false)} />
                <ViewLevelDialog open={changeViewLevel} onClose={() => setChangeViewLevel(false)} />
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {mobile && (
        <SwipeableDrawer
          variant="temporary"
          open={open}
          onClose={() => handleDrawerToggle(false)}
          onOpen={() => handleDrawerToggle(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: `${DRAWER_WIDTH}px`,
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
          ModalProps={{ keepMounted: true }}>
          {drawer}
        </SwipeableDrawer>
      )}
      {!mobile && (
        <Drawer
          variant="permanent"
          open={open}
          onClose={() => handleDrawerToggle(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: `${DRAWER_WIDTH}px`,
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              ...(!open && {
                overflowX: "hidden",
                width: `calc(${theme.spacing(7)} + 1px)`,
              }),
            },
            ...(!isLogged && { display: "none" }),
          }}>
          {drawer}
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: "100%",
          marginTop: "64px",
          overflowX: "hidden",
          overflowY: "auto",
          transition: theme.transitions.create(["padding"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ...(open
            ? {
                paddingLeft: `${DRAWER_WIDTH}px`,
                [theme.breakpoints.down("md")]: {
                  paddingLeft: 0,
                },
              }
            : {
                paddingLeft: "56px",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: 0,
                },
              }),
        }}>
        <Outlet />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        key="reconnection-snackbar"
        open={!isOnline || !validToken}>
        <CustomSnackbarContent
          variant="error"
          message={`Ooops! Nedaří se navázat spojení...`}
          action={
            <Button color="inherit" onClick={() => window.location.reload(true)}>
              Obnovit
            </Button>
          }
        />
      </Snackbar>
    </>
  );
}

export default Layout;
