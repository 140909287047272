/**
 * Construct string with suffix based of number.
 * @param {number} number Number of occurences.
 * @param {string} suffixOne Suffix for number equal to 1.
 * @param {string} suffixTwo Suffix for number between 2 and 4 inclusive.
 * @param {string} suffixThree Suffix for number equal to 0, and also if number is greater or equal to 5.
 */
export function numberWithSuffix(number = 0, suffixOne = "výskyt", suffixTwo = "výskyty", suffixThree = "výskytů") {
  const n = Math.abs(Number(number));
  // Equal 1
  if (n === 1) {
    return `${number} ${suffixOne}`;
  }
  // Between 2 and 4 inclusive
  if (n > 1 && n <= 4) {
    return `${number} ${suffixTwo}`;
  }
  // 0 or 5+
  if ((n >= 0 && n < 1) || n > 4) {
    return `${number} ${suffixThree}`;
  }
  // Otherwise
  return `[${number}]`;
}

export default numberWithSuffix;
