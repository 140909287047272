export default (state = [], action) => {
  switch (action.type) {
    case 'SNACK_ADD_REQUESTED':
      return [...state, action.data]

    case 'SNACK_REMOVE_REQUESTED':
      return [...state.slice(1)]

    default:
      return state
  }
}
