import React, { useState, useEffect, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import moment from "moment";
import Loading from "components/Loading";
import UserSelect from "components/UserSelect";
import ContactSelect from "components/ContactSelect";
import ProjectNameSelect from "components/ProjectNameSelect";
import ContactDialog from "pages/Client/Contact.dialog";
import api from "lib/axios";
import { nextFreeDirNumber, createDir } from "lib/api";
import { pad } from "lib/pad";
import { decode } from "lib/special-characters";
import escapePath from "lib/escapePath";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { orange } from "@mui/material/colors";

const initialData = {
  rok: moment().format("YYYY"),
  cislo: "000",
  stav: 1,
  klient: null,
  _clientName: "",
  kontakt: null,
  koordinator: null,
  kcislo: "",
  info: "",
  zacatek: moment().format("YYYY-MM-DD HH:mm:ss"),
  konec: moment().format("YYYY-MM-DD 18:00:00"),
  hodinovy_odhad: "",
  suma: "",
  info_lng: "",
  info_subject: "",
  cesta: "",
  po: "",
  jmeno: "",
  programy: [],
  podklady: "",
  mena: undefined,
  kcislo_duplicated: false,
  change: moment().valueOf(),
  saving: false,
  savingText: "",
  suggestedContacts: [],
};

function dataReducer(state, { type, payload }) {
  switch (type) {
    case "UPDATE":
      return { ...state, ...payload, change: moment().valueOf() };
    case "RESET":
      return { ...initialData, change: moment().valueOf() };
    default:
      return { ...state };
  }
}

function NewProjectDialog({ open, onClose }) {
  const queryClient = useQueryClient();
  const fullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const user = useSelector(({ user }) => user, shallowEqual);
  const navigate = useNavigate();
  const [data, dispatchData] = useReducer(dataReducer, initialData);
  const [contactDialog, setContactDialog] = useState(null);

  // Create project mutation
  const createProject = useMutation({
    mutationFn: ({ data }) => api.post("is_projekty", data).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dashboard-projects"] });
    },
    onError: (error) => {
      console.log("Chyba při vytváření analýzy nebo projektu!", error);
    },
  });

  /**
   * When client's nubmer is changed, check database
   * for projects which have the same number.
   */
  useEffect(() => {
    let ignore = false;
    let checkTimeout = null;

    if (open) {
      if (data.kcislo.length > 0) {
        // Throttle chechs
        checkTimeout = setTimeout(async () => {
          if (!ignore) {
            // Get projects with same client's number
            const rawDuplicates = await api
              .get("is_projekty", {
                params: { filter: `kcislo,eq,${data.kcislo}`, include: "id", page: "1,1" },
              })
              .then((res) => res.data?.records || []);
            const kcislo_duplicated = rawDuplicates.length === 0 ? false : true;
            if (!ignore) dispatchData({ type: "UPDATE", payload: { kcislo_duplicated } });
          }
        }, 300);
      } else {
        dispatchData({ type: "UPDATE", payload: { kcislo_duplicated: false } });
      }
    }

    // Handle out-of-order responses
    return () => {
      ignore = true;
      if (checkTimeout !== null) {
        clearTimeout(checkTimeout);
      }
    };
  }, [open, data.kcislo]);

  /**
   * Client is changed, get currency
   * for given client from database.
   */
  useEffect(() => {
    let ignore = false;

    if (open && data.klient) {
      const getCurrency = async () => {
        // Get currencies from database
        const currencies = await api
          .get("is_cenik", {
            params: {
              filter: [
                `klient,eq,${data.klient}`,
                "program,eq,0", // hourly rate
              ],
              include: "mena",
              sort: "id,desc",
              page: "1,1",
            },
          })
          .then((res) => res.data?.records || []);
        // Take the first one
        const payload = currencies?.[0] || { mena: undefined };
        // Save result
        if (!ignore) dispatchData({ type: "UPDATE", payload });
      };
      getCurrency();
    }
    // Handle out-of-order responses
    return () => {
      ignore = true;
    };
  }, [open, data.klient]);

  /**
   * Triggered when dialog is opened.
   * Set current user as coordinator.
   */
  const handleOpen = () => {
    const zacatek = moment().format("YYYY-MM-DD HH:mm:ss");
    let konec = moment().format("YYYY-MM-DD 18:00:00");
    if (moment(konec).isBefore(moment(zacatek))) {
      konec = moment(zacatek).add(1, "day").format("YYYY-MM-DD 18:00:00");
    }
    dispatchData({ type: "UPDATE", payload: { koordinator: user.id, zacatek, konec } });
  };

  /**
   * Close dialog.
   */
  const handleClose = () => onClose();

  /**
   * Triggered when dialog finished closing, reset data.
   */
  const handleExited = () => dispatchData({ type: "RESET" });

  /**
   * Open contact dialog.
   */
  const openContactDialog = () => setContactDialog({});

  /**
   * Close contact dialog.
   */
  const closeContactDialog = () => setContactDialog(null);

  /**
   * Generate new path.
   */
  const generatePath = (rok, cislo, jmeno, kcislo, clientName) =>
    escapePath(`${rok}${pad(cislo)}_${decode(jmeno)}_${kcislo}_${clientName}`);

  /**
   * Save analysis or project.
   */
  const handleSave = async () => {
    // Show progress "One moment, please..."
    dispatchData({
      type: "UPDATE",
      payload: {
        saving: true,
        savingText: "Zpracovávání dat...",
      },
    });

    // Parse all the info values
    const info = [];
    info.push("Jazyky: " + (data.info_lng.length > 0 ? data.info_lng : "??"));
    if (data.info_subject.length > 0) info.push(data.info_subject);
    info.push(data.info);

    // Get the first available number for new project or analysis.
    const projectNumber = { rok: moment().year(), cislo: 1 };
    // Get the latest project number from database
    const fetchedLatestRaw = await api
      .get("is_projekty", {
        params: {
          filter: [
            ["stav", data.stav === 1 ? "eq" : "gt", 1],
            ["rok", "eq", projectNumber.rok],
          ],
          page: "1,1",
          order: "cislo,DESC",
          columns: "cislo",
        },
      })
      .then((res) => res.data?.records || []);
    // Assign the latest project number
    if (fetchedLatestRaw?.[0]?.cislo) {
      projectNumber.cislo = Number(fetchedLatestRaw[0].cislo) + 1;
    }
    // Get latest number from file server
    const folderCheck = await nextFreeDirNumber(data.stav === 1 ? "Analysis" : "Projects", projectNumber.rok);
    // Parse number
    const cislo = Number(folderCheck?.result);
    // Update final number if needed
    if (cislo && cislo > projectNumber.cislo) projectNumber.cislo = cislo;

    // Construct new project object
    const newProject = {
      // id - automatically generated (PRIMARY KEY)
      ...projectNumber,
      klient: data.klient,
      kontakt: data.kontakt || null,
      koordinator: data.koordinator,
      upozorneni: data.koordinator !== user.id ? data.koordinator : "",
      kcislo: data.kcislo,
      jmeno: data.jmeno,
      zacatek: data.zacatek,
      konec: data.konec,
      stav: data.stav, // (KEY)
      // onhold - automatically set to 0
      // onhold_date - automatically set to NULL
      cesta: generatePath(projectNumber.rok, projectNumber.cislo, data.jmeno, data.kcislo, data._clientName || ""),
      //programy: data.programy.join('|'),
      info: info.join("\n----\n"),
      //podklady: data.podklady,
      postav: 1, //data.po.length > 0 ? 3 : 1,
      //po: data.po,
      //po_add_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      //po_add_user: user.id,
      // fa - not needed now; automatically set to NULL
      // fa_date - not needed now; automatically set to NULL
      created: moment().format("YYYY-MM-DD HH:mm:ss"),
      created_user: user.id,
      // edit_start - not needed now; automatically set to NULL
      // edit_start_user - not needed now; automatically set to NULL
      // edited - not needed now; automatically set to NULL
      // edited_user - not needed now; automatically set to NULL
      // deleted - not needed now; automatically set to NULL
      // deleted_user - not needed now; automatically set to NULL
      preklopeno: Number(data.stav) === 1 ? 1 : 0,
      //suma: data.suma,
      mena: data.mena || "EUR",
      //astav: Number(data.stav) === 1 ? 0 : 2,
      // nearchivovat - automatically set to 0
      //hodinovy_odhad: data.hodinovy_odhad,
      number: Number(`${projectNumber.rok}${pad(projectNumber.cislo)}`),
      // CHANGE - automatically generated
    };

    // Update progress "Saving analysis/project..."
    dispatchData({
      type: "UPDATE",
      payload: {
        ...projectNumber,
        cesta: newProject.cesta,
        savingText: `Ukládání ${Number(newProject.stav) === 1 ? "analýzy" : "projektu"}...`,
      },
    });
    // Create analysis/project in database and retrieve its ID
    const newProjectID = await createProject.mutateAsync({ data: newProject });

    if (!isNaN(newProjectID)) {
      // Create Analysis/Project folders and subfolders
      dispatchData({ type: "UPDATE", payload: { savingText: "Vytváření adresářové struktury..." } });
      if (Number(newProject.stav) === 1) {
        await createDir("Analysis", `${newProject.cesta}/01_zdrojove_soubory`);
        await createDir("Analysis", `${newProject.cesta}/02_analyza-transkit-evalkit-preflight`);
        await createDir("Analysis", `${newProject.cesta}/03_pripravene_soubory_pro_klienta`);
      } else {
        await createDir("Projects", `${newProject.cesta}/_in`);
        await createDir("Projects", `${newProject.cesta}/_out`);
        await createDir("Projects", `${newProject.cesta}/fonts`);
        await createDir("Projects", `${newProject.cesta}/production`);
        await createDir("Projects", `${newProject.cesta}/support_eval`);
        // create symlinks
        await api.get("", {
            params: {
                request: "symlinks",
                a: "create",
                p: `Projects/${newProject.cesta}`,
                sp: `Knowhow/Instrukce/${data._clientName || ""}`,
                n: "Instrukce",
            }
        });
      }

      // All done!
      dispatchData({
        type: "UPDATE",
        payload: {
          savingText: `Vytvořeno, přesměrování na ${Number(newProject.stav) === 1 ? "analýzu" : "projekt"}...`,
        },
      });
      onClose();
      // Redirect to newly created project/analysis
      navigate(`/p/${newProjectID}`);
    } else {
      console.log("Chyba při vytváření analýzy/projektu!");
      dispatchData({ type: "UPDATE", payload: { saving: false } });
    }
  };

  /**
   * Returns boolean:
   * - True if data are valid
   * - False otherwise
   */
  const isValid = useMemo(() => {
    // Client
    if (!data.klient) return false;
    // Contacts
    if (!data.kontakt) return false;
    // Email subject
    if (!data.info_subject?.length) return false;
    // Client's number
    if (!data.kcislo?.length) return false;
    // Name
    if (!data.jmeno?.length) return false;
    // Otherwise
    return true;
  }, [data]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => handleClose}
      aria-labelledby="newproject-dialog-title"
      disableEscapeKeyDown
      //scroll="paper"
      TransitionProps={{
        onEnter: () => handleOpen(),
        onExited: () => handleExited(),
      }}>
      <DialogTitle id="newproject-dialog-title">{data.stav === 1 ? "Nová analýza" : "Nový projekt"}</DialogTitle>
      <DialogContent dividers>
        <Collapse in={data.saving === true}>
          <Loading text={data.savingText} spaceAround />
        </Collapse>
        <Collapse in={data.saving === false}>
          <form noValidate autoComplete="off" onSubmit={(event) => event.preventDefault()}>
            <Box marginBottom={4}>
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                })}>
                Jedná se o nový projekt nebo analýzu?
              </Typography>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}>
                <ToggleButtonGroup
                  value={data.stav}
                  exclusive
                  size="large"
                  onChange={(event, stav) => stav && dispatchData({ type: "UPDATE", payload: { stav } })}
                  aria-label="Jedná se o nový projekt nebo analýzu?"
                  color="primary"
                  style={{ width: "100%", display: "flex", justifyContent: "stretch" }}>
                  <ToggleButton value={1} aria-label="nová analýza" style={{ flex: "1 1 auto" }}>
                    Nová analýza
                  </ToggleButton>
                  <ToggleButton value={2} aria-label="nový projekt" style={{ flex: "1 1 auto" }}>
                    Nový projekt
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
            <Box marginBottom={4}>
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                })}>
                Klient a koordinátoři
              </Typography>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}>
                <UserSelect
                  value={data.koordinator}
                  onChange={(value) => {
                    if (value?.id) dispatchData({ type: "UPDATE", payload: { koordinator: Number(value.id) } });
                  }}
                  inputProps={{ label: "Koordinátor", required: true }}
                />
              </FormControl>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}>
                <ContactSelect
                  value={data.kontakt}
                  inputProps={{ required: true }}
                  onChange={(value) =>
                    dispatchData({
                      type: "UPDATE",
                      payload: {
                        kontakt: value?.id,
                        klient: value?.klient_id?.id,
                        _clientName: value?.klient_id?.name || "",
                      },
                    })
                  }
                />
                <Box pt={1}>
                  <Chip
                    icon={<PersonAddOutlinedIcon />}
                    onClick={openContactDialog}
                    label="Nový kontakt"
                    tabIndex={-1}
                    size="small"
                  />
                </Box>
              </FormControl>
              <ContactDialog
                open={Boolean(contactDialog)}
                inputData={contactDialog}
                onClose={(newData) => closeContactDialog(newData)}
                clientID={data?.klient}
              />
            </Box>
            <Box marginBottom={4}>
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                  marginBottom: theme.spacing(2),
                })}>
                Základní informace
              </Typography>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}>
                <TextField
                  id="newproject-info-subject"
                  fullWidth
                  variant="outlined"
                  value={data.info_subject || ""}
                  label="E-mailový subjekt"
                  multiline
                  onChange={(event) =>
                    dispatchData({
                      type: "UPDATE",
                      payload: {
                        info_subject: event.target.value,
                      },
                    })
                  }
                  required
                />
              </FormControl>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}
                required>
                <TextField
                  id="newproject-kcislo"
                  variant="outlined"
                  label="Klientovo číslo"
                  value={data.kcislo || ""}
                  fullWidth
                  required
                  onChange={(event) =>
                    dispatchData({
                      type: "UPDATE",
                      payload: {
                        kcislo: event.target.value,
                      },
                    })
                  }
                  //error={data.kcislo_duplicated !== false}
                  helperText={
                    data?.kcislo_duplicated !== false && (
                      <Box
                        component="span"
                        sx={{
                          color: orange[500],
                        }}>
                        Projekt s tímto číslem již existuje!
                      </Box>
                    )
                  }
                />
              </FormControl>
              <FormControl
                sx={(theme) => ({
                  display: "block",
                  flexGrow: 1,
                  margin: theme.spacing(1),
                  marginBottom: theme.spacing(2),
                })}
                required>
                <ProjectNameSelect
                  value={data.jmeno || ""}
                  onChange={(jmeno) => dispatchData({ type: "UPDATE", payload: { jmeno } })}
                  inputProps={{ label: "Název", required: true }}
                />
              </FormControl>
            </Box>
          </form>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose} disabled={data.saving}>
          Zrušit
        </Button>
        <Button onClick={handleSave} disabled={!isValid || data.saving}>
          Vytvořit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewProjectDialog.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

NewProjectDialog.defaultProps = {
  type: "A", // 'A' = Analysis; 'P' = Project
  open: false,
  onClose: () => {},
};

export default NewProjectDialog;
