/**
 * Return existing property value of an object or default value.
 *
 * @param {function} testFn Test fuction that return some value.
 * @param {any} defaultValue Default value returned if error
 */
const readProp = (testFn: () => any, defaultValue?: any) => {
  try {
    return testFn();
  } catch (e) {
    //console.log(e) // eslint-disable-line no-console
    return defaultValue;
  }
};

export default readProp;
