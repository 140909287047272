import React from "react";
import { createRoot } from "react-dom/client";
//import registerServiceWorker from 'lib/registerServiceWorker'
import { unregister } from "lib/registerServiceWorker";
import App from "./App";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import csMomentLocale from "lib/cs-moment-locale";
import "moment/locale/cs";

// Load fonts
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Add duration format functionality to moment
// https://github.com/jsmreese/moment-duration-format
momentDurationFormatSetup(moment);
// Set momentjs locale
moment.locale("cs");
moment.updateLocale("cs", csMomentLocale);

const rootEl = document.getElementById("root");

const root = createRoot(rootEl);

root.render(<App />);

// Service worker
//registerServiceWorker()
unregister();
