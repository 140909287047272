import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton, Badge, Popover, Typography, List, ListItem, ListItemText, Box } from "@mui/material";
import IconNotificationsNone from "@mui/icons-material/NotificationsNoneOutlined";
import IconCheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import { useNotifications } from "lib/hooks/useNotifications";
import desktopNotification from "lib/desktopNotification";
import numberWithSuffix from "lib/numberWithSuffix";
import moment from "moment";
import api from "lib/axios";

function Notifications() {
  const navigate = useNavigate();
  const user = useSelector(({ user }) => user, shallowEqual);
  const [open, setOpen] = useState(false);
  const [notified, setNotified] = useState([]);

  // Show/hide badge
  const showBadge = useMemo(() => {
    const notViewed = notified.filter((n) => !n?.viewedInApp);
    return notViewed.length > 0;
  }, [notified]);

  // Notification button reference
  const btnRef = useRef(null);

  // Get notifications
  const { notifications } = useNotifications(user?.id);

  // Handle notification changes
  useEffect(() => {
    if (Array.isArray(notifications) && !!notifications.length) {
      let newItems = [];
      let prev = [...notified];
      const next = notifications.sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0));
      // Remove previous lunch break notification
      prev = prev.filter((p) => p.type !== "lunch");
      // Go trough each notification
      for (const [, item] of next.entries()) {
        // Ignore new absence if user do not have rights
        if (item.type === "absence" && !item.edited && user.userLevel < 8) break;
        // Construct unique ID
        let itemUID = moment(item.timestamp).valueOf();
        itemUID += "-";
        itemUID += item.type;
        itemUID += "-";
        itemUID += item?.id || "-";
        // Check if already notified
        if (!prev.find((n) => n?.uid === itemUID)) {
          // Add new item
          newItems.push({ ...item, uid: itemUID, viewedInApp: false });
          // In case this item is number of users waitng for work,
          // remove this kind from previous
          if (item.type === "waiting") {
            prev = prev.filter((p) => p.type !== "waiting");
          }
        }
      }
      // Notify user
      handleNotification(newItems);
      // Update DB
      handleUpdateDB(newItems);
      // Add new items
      setNotified(newItems.concat(prev));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  /**
   * Notify user.
   * @param {array} items Array with new items
   */
  const handleNotification = (items) => {
    const filtered = items.filter((item) => {
      // Show notifications types beginning with underscore only in development mode
      if (String(item?.type || "").charAt(0) === "_" && process?.env?.NODE_ENV !== "development") {
        return false;
      }
      return true;
    });

    if (filtered.length > 1) {
      desktopNotification(`${numberWithSuffix(filtered.length, "nové", "nové", "nových")} upozornění`);
    } else {
      if (filtered.length === 1) {
        const item = filtered[0];
        if (item.type === "projects") {
          desktopNotification(
            `${
              Number(item.stav) > 1
                ? item.edited
                  ? "Změna v projektu"
                  : "Nový projekt"
                : item.edited
                ? "Změna v analýze"
                : "Nová analýza"
            } ${item.number}`
          );
        }
        if (item.type === "waiting") {
          desktopNotification(`${numberWithSuffix(item.count, "člověk čeká", "lidi čekají", "lidí čeká")} na práci`);
        }
        if (item.type === "tasks") {
          desktopNotification("Nový úkol!");
        }
        // Absence
        if (item.type === "absence") {
          if (item?.edited) {
            let msg = "Změna absence";
            if (Number(item.typ) === 3) {
              if (item.schvaleno === null) msg = "Změna dovolené";
              if (item.schvaleno === 0) msg = "Dovolená byla zamítnuta";
              if (item.schvaleno === 1) msg = "Dovolená byla schválena";
            }
            if (Number(item.typ) === 4) msg = "Změna v záznamu o nemoci";
            if (Number(item.typ) === 5) {
              if (item.schvaleno === null) msg = "Změna pracovního volna";
              if (item.schvaleno === 0) msg = "Pracovní volno bylo zamítnuto";
              if (item.schvaleno === 1) msg = "Pracovní volno bylo schváleno";
            }
            desktopNotification(msg);
          } else {
            let msg = "Nová absence";
            if (Number(item.typ) === 3) msg = "Nový požadavek na dovolenou";
            if (Number(item.typ) === 4) msg = "Nový záznam o nemoci";
            if (Number(item.typ) === 5) msg = "Nový požadavek na pracovní volno";
            desktopNotification(msg);
          }
        }
        // Lunch break
        if (item.type === "lunch") {
          if (item?.duration) {
            desktopNotification("Pořád papáš? :)");
          }
        }
      }
    }
  };

  /**
   * Update DB, set that the user was already notified.
   * @param {array} items Array with new notification objects
   */
  const handleUpdateDB = (items) => {
    // TODO: Je mozne upravit nekolik polozek naraz, neni nutne delat nekolik jednotlivych aktualizaci databaze
    //const projectsUpdateList = items.filter(p => p.type === 'projects')
    //const tasksUpdateList = items.filter(p => p.type === 'tasks')

    items.forEach((item) => {
      if (item.type === "projects") {
        const upozorneni = item.upozorneni
          .split("|")
          .filter((u) => Number(u) !== Number(user.id))
          .map((u) => Number(u))
          .join("|");
        api.put(`is_projekty/${item.id}`, { upozorneni });
      }
      if (item.type === "tasks") {
        const upozorneni = item.upozorneni
          .split("|")
          .filter((u) => Number(u) !== Number(user.id))
          .map((u) => Number(u))
          .join("|");
        api.put(`is_eventy/${item.id}`, { upozorneni });
      }
    });
  };

  /**
   * Close quick search dialog and redirect.
   * @param {string} pathname Path to redirect.
   */
  const redirect = (pathname) => {
    setOpen(false);
    navigate(pathname);
  };

  /**
   * Return formatted date string.
   * @param {string} timestamp Timestamp string.
   */
  const getTime = (timestamp) => {
    const m = moment(timestamp);
    if (m.isValid()) {
      //return moment.duration(moment().diff(m)).humanize()
      if (m.isBefore(moment(), "day")) return m.format("YYYY-MM-DD HH:mm:ss");
      return m.format("H:mm:ss");
    } else return "Před chvílí";
  };

  /**
   * Once in-app notifications are shown,
   * run timeout to make all of them as viewed.
   */
  const handleNotificationsOpen = () => {
    setTimeout(() => {
      if (setNotified) {
        const updatedNotified = notified.map((n) => ({ ...n, viewedInApp: true }));
        setNotified(updatedNotified);
      }
    }, 1000);
  };

  /**
   * Render notification item.
   * @prop {Object} item Notification object
   */
  const renderItem = (item, index) => {
    let primaryText = item?.type || "";
    let destination = "/";

    // Show notifications types beginning with underscore only in development mode
    if (String(primaryText).charAt(0) === "_" && process?.env?.NODE_ENV !== "development") {
      return;
    }

    // Projects
    if (item.type === "projects") {
      primaryText = `${
        Number(item.stav) > 1
          ? item.edited
            ? "Změna v projektu"
            : "Nový projekt"
          : item.edited
          ? "Změna v analýze"
          : "Nová analýza"
      } ${item.number}`;
      destination = `/p/${item.id}`;
    }
    // Waiting for work
    if (item.type === "waiting") {
      primaryText = `${numberWithSuffix(item.count, "člověk čeká", "lidi čekají", "lidí čeká")} na práci`;
    }
    // Tasks
    if (item.type === "tasks") {
      // TODO: pokud je edited novejsi nez created, jedna se o aktualizovany ukol
      primaryText = "Nový úkol";
    }
    // Absence
    if (item.type === "absence") {
      if (item?.edited) {
        primaryText = "Změna absence";
        if (Number(item.typ) === 3) {
          if (item.schvaleno === null) primaryText = "Změna dovolené";
          if (item.schvaleno === 0) primaryText = "Dovolená byla zamítnuta";
          if (item.schvaleno === 1) primaryText = "Dovolená byla schválena";
        }
        if (Number(item.typ) === 4) primaryText = "Změna v záznamu o nemoci";
        if (Number(item.typ) === 5) {
          if (item.schvaleno === null) primaryText = "Změna pracovního volna";
          if (item.schvaleno === 0) primaryText = "Pracovní volno bylo zamítnuto";
          if (item.schvaleno === 1) primaryText = "Pracovní volno bylo schváleno";
        }
      } else {
        primaryText = "Nová absence";
        if (Number(item.typ) === 3) primaryText = "Nový požadavek na dovolenou";
        if (Number(item.typ) === 4) primaryText = "Nový záznam o nemoci";
        if (Number(item.typ) === 5) primaryText = "Nový požadavek na pracovní volno";
      }
    }
    // Lunch break notification
    if (item.type === "lunch") {
      primaryText = `Obědová pauza běží déle jak ${item.duration} minut!`;
    }

    return (
      <ListItem key={index} button onClick={() => redirect(destination)}>
        <ListItemText
          primary={primaryText}
          primaryTypographyProps={{
            color: item?.viewedInApp ? "textSecondary" : "textPrimary",
          }}
        />
        <Typography variant="body2" color="textSecondary">
          {getTime(item.timestamp)}
        </Typography>
      </ListItem>
    );
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" ref={btnRef} onClick={() => setOpen(true)} size="large">
        <Badge color="secondary" variant="dot" invisible={!showBadge}>
          <IconNotificationsNone />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={btnRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setOpen(false)}
        sx={(theme) => ({
          "& .MuiPopover-paper": {
            width: "100%",
            [theme.breakpoints.up("md")]: {
              maxWidth: 400,
            },
          },
        })}
        TransitionProps={{
          onEntered: handleNotificationsOpen,
        }}>
        {notified?.length ? (
          <React.Fragment>
            <Typography variant="body2" color="inherit" component="div">
              <Box
                sx={(theme) => ({
                  padding: theme.spacing(2),
                  color: theme.palette.text.disabled,
                  fontWeight: 500,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                })}>
                Upozornění
              </Box>
            </Typography>
            <List
              disablePadding
              //classes={{ root: classes.listRoot }}
              sx={(theme) => ({
                "& .MuiListItem-root": {
                  "& > div": {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    "&:last-child": {
                      borderBottom: 0,
                    },
                  },
                },
              })}>
              {notified.map(renderItem)}
            </List>
          </React.Fragment>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: (theme) => theme.spacing(10),
            }}>
            <IconCheckCircleOutlined
              sx={{
                color: (theme) => theme.palette.text.disabled,
                fontSize: 128,
              }}
            />
            <Typography
              sx={(theme) => ({
                paddingTop: theme.spacing(2),
                textAlign: "center",
                color: theme.palette.text.disabled,
              })}>
              Žádné upozornění.
            </Typography>
          </Box>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default Notifications;
