import moment from 'moment'

const initialState = {
  //_lastCheck: null,
  //_isChecking: false,
  //_storableTables: [
  //  'is_cenik',
  //  //'is_cenik_info',
  //  'is_klient',
  //  'is_kontakt',
  //  'is_po_stavy',
  //  'is_prava',
  //  'is_programy',
  //  'is_projekt_stavy',
  //  'is_svatky',
  //  'is_uzivatel',
  //  'is_zakladni_aktivity',
  //],
  //_lastChanges: {},
  //_data: {},
  //stored: {},
  valid_token: false,
}

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case 'DB_RESET_LASTCHECK': {
      return { ...state, _lastCheck: action.lastCheck }
    }
    case 'DB_RESET_LASTCHANGE_TABLE': {
      return {
        ...state,
        _lastChanges: { ...state._lastChanges, [action.table]: action.lastChange },
      }
    }
    case 'DB_UPDATED_LASTCHANGES': {
      //console.log(action.lastChanges)
      return {
        ...state,
        ...{ _lastChanges: { ...action.lastChanges } },
      }
    }
    case 'DB_CHECK_REQUESTED': {
      return { ...state, _isChecking: true }
    }
    case 'DB_UPDATED_DATA': {
      return {
        ...state,
        _isChecking: false,
        _lastCheck: moment().valueOf(),
        ...{ _data: action.data },
      }
    }
    case 'DB_CHECK_COMPLETED': {
      return { ...state, _isChecking: false, _lastCheck: moment().valueOf() }
    }
    case 'DB_STORE_DATA': {
      return {
        ...state,
        ...{
          stored: {
            ...state.stored,
            ...{ [action.key]: { lastChange: action.lastChange, data: action.data } },
          },
        },
      }
    }
    case 'DB_CHECK_FAILED': {
      return { ...state, _isChecking: false, ...{ error: action.error } }
    }
    case 'DB_TOKEN_INVALID': {
      return { ...state, valid_token: false }
    }
    case 'DB_TOKEN_UPDATED': {
      return { ...state, valid_token: true }
    }
    default:
      return state
  }
}
